import React from "react";
import { Container, Row } from "react-bootstrap";
import { HashLink as Link } from 'react-router-hash-link';
import blog2 from "../../Assets/Blog3_Foto2.webp";
import blog6 from "../../Assets/Blog3_Foto6.webp";

function PlusSizeCourseContent() {
    return (
<Container className="plus-about-section">
    <Row md={13} style={{ justifyContent: "center", color: "white", padding: "20px"}}>
    <p>Mit Plus Size Yoga, auch oft bezeichnet als Curvy Yoga, habe ich als Yogalehrerin meine Nische
gefunden, auf welche ich zukünftig einen ganz besonderen Fokus legen möchte.</p>
<p>Aber warum biete ich Online Plus Size Yoga und Curvy Yoga speziell für Frauen an? Diese Frage hat
eine ganz persönliche Geschichte. Denn ich <b>selbst war anfangs eine Frau, die sich unsicher und
ängstlich fühlte, weil ihr eigener Körper scheinbar nicht in die Yoga-Welt passte</b>, wie sie heutzutage
in den sozialen Medien präsentiert wird. Am Anfang meiner Yoga-Reise traute ich mich nicht einmal, ein
physisches Yoga-Studio zu besuchen, aus Angst vor neugierigen Blicken und herablassenden
Kommentaren.</p>
<p>Als ich vor vier Jahren aufgrund meines stressigen Arbeitsalltages zum ersten Mal mit dem Gedanken
spielte, mit Yoga zu beginnen, fiel mir auf, dass mein Körper nicht dem entsprach, was ich in den sozialen
Medien oft sah. Die scheinbar endlosen Bilder von jungen, schlanken und überaus flexiblen Yogis und
Yoginis haben mich damals zu Beginn meiner Yoga-Reise durchaus verunsichert. Ich stellte mir die
Frage, ob ich überhaupt Yoga praktizieren könnte, geschweige denn einen echten Nutzen daraus ziehen
könne, da ich ein paar Kilos mehr auf den Rippen habe und meine Gelenke nicht so beweglich schienen,
wie die der Personen auf den Bildern.
Die überwältigende Präsenz dieser "Yoga-Ideale" in den sozialen Medien ließ mich anfangs an mir selbst
zweifeln. Doch die <b>Sehnsucht nach innerem Frieden und einer besseren Verbindung zu meinem
Körper</b> trieb mich an, meine Zweifel zu überwinden und Yoga auszuprobieren. So startete ich meine
tägliche Yoga-Praxis, vorerst in meinen eigenen vier Wänden mittels Youtube-Videos und Online Kursen.
</p>
<p>Mit der Zeit wurde ich mutiger und ruhiger. Ich bemerkte, wie Yoga nicht nur meinen Körper,
sondern auch meinen Geist veränderte. Die Selbstzweifel und Unsicherheiten, die mich zu Beginn
begleitet hatten, begannen sich aufzulösen. Ich realisierte, dass Yoga weit mehr ist als die äußere
Darstellung in den sozialen Medien. Es ist eine <b>persönliche Reise zur Selbstliebe, Selbstakzeptanz
und innerem Frieden</b>. Die Yoga-Philosophie lehrte mich, dass es keine Norm gibt, an die ich mich
anpassen muss. Jeder Körper ist anders, und jeder Mensch hat seine eigenen Grenzen und Potenziale. Im
Yoga geht es darum, <b>sich selbst zu akzeptieren und sich auf die persönliche Entwicklung zu
konzentrieren, unabhängig von äußeren Erwartungen oder Standards</b>. In mir entwickelte sich der
große Wunsch diese Erkenntnisse in die Welt hinauszutragen, um Menschen dabei zu helfen, zu sich
selbst zu finden und sich selbst zu akzeptieren. Aus diesem Grund entschied ich, die Ausbildung zur
Yogalehrerin zu absolvieren.</p>
<p>Mein persönlicher Yoga-Weg hat mich gelehrt, dass die <b>wahre Schönheit des Yoga in der
Vielfalt</b> liegt. Jeder kann von dieser uralten Praxis profitieren, unabhängig von Körpertyp oder
Fitnesslevel. Die Yoga-Community sollte ein Ort der Unterstützung, des Verständnisses und der
Akzeptanz sein. Denn letztendlich ist Yoga eine Reise zu sich selbst – eine Reise, die uns lehrt, uns selbst
zu lieben und inneren Frieden zu finden, unabhängig von den äußeren Einflüssen und Erwartungen.</p>

<img src={blog2} alt="Tree Pose" style={{ maxWidth: '90%', maxHeight: '500px', paddingBottom: '20px', paddingTop: '20px', objectFit: 'contain' }} />


<h2>Warum Plus Size Yoga und Curvy Yoga so wichtig sind</h2>
<p>Insgesamt möchte ich durch das Angebot von Plus Size und Curvy Yoga unter anderem dazu
beitragen, die <b>Yoga-Gemeinschaft und die Gesellschaft im Allgemeinen inklusiver und vielfältiger zu
gestalten</b>. Hier sind noch weitere Gründe, warum ich denke, dass dieses Angebot wichtig ist:</p>

<p><li><b>Inklusion und Vielfalt: </b>Yoga sollte für alle Menschen zugänglich sein, unabhängig von
ihrer Körperform, -größe oder ihrem Fitnesslevel. Plus Size und Curvy Yoga schafft eine inklusive
Umgebung, die Menschen ermutigt, Yoga zu praktizieren, ohne sich wegen ihres Körpers zu schämen
oder ausgeschlossen zu fühlen.</li></p>
<p><li><b>Körperpositivität: </b>Plus Size und Curvy Yoga fördert die Botschaft der Körperpositivität.
Es lehrt Menschen, ihren Körper zu akzeptieren und zu schätzen, unabhängig von gesellschaftlichen
Schönheitsstandards. Dies kann dazu beitragen, das Selbstwertgefühl und das Selbstvertrauen zu steigern.
</li></p>
<p><li><b>Gesundheit und Wohlbefinden: </b>Yoga bietet eine Vielzahl von gesundheitlichen Vorteilen,
darunter Stressabbau, Flexibilität, Stärkung der Muskulatur und Verbesserung der Körperhaltung. Diese
Vorteile sind für Menschen jeden Körpertyps von Bedeutung.</li></p>
<p><li><b>Stressabbau und psychische Gesundheit: </b>Yoga kann dazu beitragen, Stress abzubauen
und das allgemeine Wohlbefinden zu steigern. Für Menschen, die sich aufgrund ihres Körpers gestresst
oder unwohl fühlen, kann Plus Size und Curvy Yoga eine besonders unterstützende Möglichkeit bieten,
um innere Ruhe zu finden.</li></p>
<p><li><b>Gemeinschaft und Unterstützung: </b>Plus Size und Curvy Yoga schafft eine Gemeinschaft
von Gleichgesinnten, die ähnliche Erfahrungen teilen. Diese Gemeinschaft bietet emotionalen Support
und Verständnis, was die Motivation zur Yoga-Praxis erhöht.</li></p>
<p><li><b>Selbstakzeptanz und Selbstliebe: </b>Durch die Praxis von Yoga lernen Menschen, sich
selbst zu lieben und zu akzeptieren, so wie sie sind. Es fördert das Bewusstsein für den eigenen Körper
und die Verbindung zu ihm.</li></p>
<p><li><b>Bildung und Aufklärung: </b>Plus Size und Curvy Yoga kann auch dazu beitragen,
Missverständnisse und Vorurteile über Körpergröße und Gesundheit aufzuklären. Es zeigt, dass
Gesundheit und Fitness nicht zwangsläufig mit dem äußeren Erscheinungsbild korrelieren.</li></p>
<p><li><b>Empowerment: </b>Die Praxis von Plus Size und Curvy Yoga verleiht Menschen ein Gefühl
von Stärke und Selbstbewusstsein. Es kann dazu führen, dass sie sich in anderen Bereichen ihres Lebens
selbstbewusster und stärker fühlen.</li></p>

<img src={blog6} alt="Forward Fold" style={{ maxWidth: '90%', maxHeight: '500px', paddingBottom: '20px', paddingTop: '20px',objectFit: 'contain' }} />

<h2>Mein Ziel für meine Plus Size und Curvy Yoga Stunden</h2>
<p>Meine Vision ist es, einen <b>sicheren und einladenden Ort zu schaffen, an dem sich Plus Size und
Curvy Frauen wohl und akzeptiert</b> fühlen. In meinen Plus Size und Curvy Yoga Stunden können
diese Frauen in einer sicheren und vertrauten Umgebung praktizieren, ohne Angst vor Blicken oder
Beurteilungen - an einem Ort, an dem sie ohne Druck und Angst ihrer Yogapraxis nachgehen können.</p>
<p>Ich glaube fest daran, dass Yoga für jeden Körper und jedes Fitnesslevel zugänglich sein sollte. Jeder
verdient die heilenden und stärkenden Vorteile des Yoga, unabhängig von äußeren Erwartungen oder
Standards. Ich möchte diese Frauen ermutigen, sich selbst zu feiern, ihre eigenen Grenzen zu erkunden
und ihre Stärken zu entdecken. Denn Yoga sollte nicht dazu dienen, uns zu verändern, sondern uns dabei
helfen, uns selbst besser zu verstehen und zu schätzen.</p>
<p>Wenn du dich angesprochen fühlst, dann <b>werde Teil meiner Plus Size Yoga & Curvy Yoga
Community</b> und lasse uns gemeinsam diese wunderbare Reise zur Selbstliebe und innerem Frieden
antreten. Wenn du dich zu meinen <b>Online Plus Size & Curvy Yoga Stunden anmelden</b> möchtest,
dann kannst du dies gerne <Link className="purpledark" as={Link} to="/contact">
               hier
        </Link> tun.</p>

<p>Du bist herzlich willkommen, und ich freue mich sehr darauf, dich auf deinem Weg zu begleiten!</p>
    </Row>

        
</Container>
    )
}
export default PlusSizeCourseContent;
