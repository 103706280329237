import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import PlusSizeCourseContent from "./PlusSizeContent"


function PlusSize() {
  return (
    <section>
    <Container fluid="true" className="plus-section" id="kurs">
      <Particle />
      <Container className="about-section">
      <Row md={13} style={{ justifyContent: "center", padding: "10px" }}>
      <Col style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "0px",
            }}>
        <h1 style={{ fontSize: "3.1em", paddingBottom: "10px" }}>
        Plus Size Yoga & Curvy Yoga für Frauen</h1>
        
      </Col>
      </Row>
      </Container>
    </Container>
    <PlusSizeCourseContent></PlusSizeCourseContent>
  </section>
  );
}

export default PlusSize;
