import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/Home1.webp";
import myImg2 from "../../Assets/Home2.webp";
import myImg3 from "../../Assets/Home3.webp";
import {Link} from "react-router-dom";
import Type from "./Type";
import AutoPlay from "./AutoPlay";
import AutoPlayMobile from "./AutoPlayMobile";
import {isMobile} from 'react-device-detect';
import { Slide } from "react-awesome-reveal";


function Home2() {

  if (isMobile)
  {
    return (
      <Container fluid="true" className="home-about-section" id="about">
      <Container>
      <Type />
        <Row>
        <Col md={12} className="home-about-welcome">
        <p className="home-about-body">
        <b>Herzlich Willkommen!</b> Ich freue mich sehr, dass du den Weg zu mir gefunden hast und hoffe, dass du hier findest was du suchst. Ich bin zertifizierte Yogalehrerin und biete <b>Online Yogakurse für jeden Körper und jedes Flexibilitätslevel</b> an. Es mir ein <b>großes Anliegen, den unglaublichen Mehrwert, den Yoga mit sich bringt, in die Welt hinauszutragen</b>. Dabei ist es mir besonders wichtig möglichst viele Menschen anzusprechen. Aus diesem Grund eignen sich meine Yogakurse sowohl für Anfänger als auch für Fortgeschrittene, da ich stets <b>unterschiedliche Optionen für die Durchführung einer Asana</b> anbiete und du somit den Unterricht an deine <b>körperlichen Bedürfnisse und Voraussetzungen</b> anpassen kannst. So oft wird angepriesen, dass die Asanapraxis von jedem Körper unabhängig der Flexibilität praktiziert werden kann. Ich stimme dem zu 100% zu und sehe es als zentrale (wenn nicht sogar als die wichtigste) Aufgabe von Yogalehrer:innen an, jedem/jeder Schüler:in das <b>Gefühl zu geben genug zu sein</b> und genug zu können. Frust soll bei mir im Unterricht keine Chance haben, denn <b>niemand muss mit der Stirn das Schienbein oder mit den Händen den Boden berühren</b>, um von Yoga zu profitieren. Ich möchte den Menschen einen Safe Space bieten, in welchem sie sich so zeigen können wie sie nun mal sind <b>ohne Bewertung und ohne Druck</b>.
<p></p>
Da Yogaunterricht in der Gruppe nicht jedermanns Sache ist, umfasst mein Angebot auch private online Yogastunden. Dauer, Anzahl der Einheiten, Zeit sowie den Fokus der Stunden können wir gerne individuell vereinbaren.
<p></p>
Solltest du Fragen haben (zB. zu meinen Online Yogakursen, zu privaten Yogaunterricht, zu Yogaunterricht für Unternehmen etc.) zögere nicht dich bei mir  <Link className="purpledark" as={Link} to="/contact">
               hier
        </Link> zu melden. Ich freue mich auf deine Nachricht!</p>
</Col>
        </Row>

    <AutoPlayMobile />

        <Row>
        <h2 className="home-about-mission"  style={{ fontSize: "2.6em" }}>
              Meine Mission als Yogalehrerin
            </h2>
          <Col md={8} className="home-about-description">
          <p className="home-about-body">
            <Slide triggerOnce duration={2000}> 
            <h2>SAFE SPACE</h2>
            </Slide>

<p>Meine Mission ist es, einen sicheren Raum zu schaffen, in dem DU so akzeptiert wirst, wie DU bist. In unserer hektischen Welt, die von Stressoren geprägt ist, möchte ich DIR eine Stunde bieten, in der DU die Herausforderungen des Alltags hinter dir lassen kannst. Mein Ziel ist es, DIR dabei zu helfen, den Spaß am Yoga zu entdecken und DEINE Gedanken zur Ruhe kommen zu lassen.</p>
<Slide triggerOnce duration={2000}>
<h2>INKLUSION</h2>
</Slide>


<p>Ich möchte DIR zeigen, dass Yoga für alle Körper – einschließlich Plus-Size-Körper – geeignet ist. Ich möchte inklusive Umgebung schaffen, in der jeder Mensch die positiven Wirkungen von Yoga erfahren kann, unabhängig von Größe oder Form. Durch achtsame und angepasste Praktiken strebe ich danach, dass DEIN Wohlbefinden und DEINE Selbstakzeptanz zu fördern. </p>
<Slide triggerOnce duration={2000}>
<h2>KREATIVITÄT</h2>
</Slide>

<p>Ich strebe nach Vielfalt und Abwechslung, indem ich meine Yogastunden mit einer Mischung aus traditionellen Asanas und kreativen Posen gestalte. Durch diese Kombination möchte ich DICH dazu ermutigen, DICH auszuprobieren, DEINE Grenzen zu erweitern und DEINEN eigenen Ausdruck im Yoga zu finden. </p>

<Slide triggerOnce duration={2000}>
<h2>ENTWICKLUNG</h2>
</Slide>

<p>In meinem Unterricht geht es nicht nur um das körperliche Training, sondern auch um die ganzheitliche Entwicklung jedes Einzelnen. Mein Ziel ist es, DIR zu helfen, DEINE innere Ruhe zu finden, DEINE Gesundheit zu fördern und eine positive Veränderung in DEINEM Leben zu erleben. Mit Leidenschaft, Empathie und einem respektvollen Umgang begleite ich DICH auf DEINER Yogareise und stehe DIR dabei zur Seite, DEIN volles Potenzial zu entfalten. </p>

            </p>
          </Col>
          <Col md={4} className="myAvtar">
              <img src={myImg} className="img-fluid" alt="Salamander" />
              <img src={myImg2} className="img-fluid" alt="Twist" />
              <img src={myImg3} className="img-fluid" alt="Wild Thing" />
          </Col>
        </Row>
        <Row>
        </Row>
      </Container>
    </Container>
  );
  }

  return (
    <Container fluid="true" className="home-about-section" id="about">
      <Container>
      <Type />
        <Row>
        <Col md={12} className="home-about-welcome">
        <p className="home-about-body">
        <b>Herzlich Willkommen!</b> Ich freue mich sehr, dass du den Weg zu mir gefunden hast und hoffe, dass du hier findest was du suchst. Ich bin zertifizierte Yogalehrerin und biete <b>Online Yogakurse für jeden Körper und jedes Flexibilitätslevel</b> an. Es mir ein <b>großes Anliegen, den unglaublichen Mehrwert, den Yoga mit sich bringt, in die Welt hinauszutragen</b>. Dabei ist es mir besonders wichtig möglichst viele Menschen anzusprechen. Aus diesem Grund eignen sich meine Yogakurse sowohl für Anfänger als auch für Fortgeschrittene, da ich stets <b>unterschiedliche Optionen für die Durchführung einer Asana</b> anbiete und du somit den Unterricht an deine <b>körperlichen Bedürfnisse und Voraussetzungen</b> anpassen kannst. So oft wird angepriesen, dass die Asanapraxis von jedem Körper unabhängig der Flexibilität praktiziert werden kann. Ich stimme dem zu 100% zu und sehe es als zentrale (wenn nicht sogar als die wichtigste) Aufgabe von Yogalehrer:innen an, jedem/jeder Schüler:in das <b>Gefühl zu geben genug zu sein</b> und genug zu können. Frust soll bei mir im Unterricht keine Chance haben, denn <b>niemand muss mit der Stirn das Schienbein oder mit den Händen den Boden berühren</b>, um von Yoga zu profitieren. Ich möchte den Menschen einen Safe Space bieten, in welchem sie sich so zeigen können wie sie nun mal sind <b>ohne Bewertung und ohne Druck</b>.
<p></p>
Da Yogaunterricht in der Gruppe nicht jedermanns Sache ist, umfasst mein Angebot auch private online Yogastunden. Dauer, Anzahl der Einheiten, Zeit sowie den Fokus der Stunden können wir gerne individuell vereinbaren.
<p></p>
Solltest du Fragen haben (zB. zu meinen Online Yogakursen, zu privaten Yogaunterricht, zu Yogaunterricht für Unternehmen etc.) zögere nicht dich bei mir  <Link className="purpledark" as={Link} to="/contact">
               hier
        </Link> zu melden. Ich freue mich auf deine Nachricht!</p>
</Col>
        </Row>

    <AutoPlay />

        <Row>
        <h2 className="home-about-mission"  style={{ fontSize: "2.6em" }}>
              Meine Mission als Yogalehrerin
            </h2>
          <Col md={8} className="home-about-description">
            <p className="home-about-body">
            <Slide triggerOnce duration={2000}> 
            <h2>SAFE SPACE</h2>
            </Slide>

<p>Meine Mission ist es, einen sicheren Raum zu schaffen, in dem DU so akzeptiert wirst, wie DU bist. In unserer hektischen Welt, die von Stressoren geprägt ist, möchte ich DIR eine Stunde bieten, in der DU die Herausforderungen des Alltags hinter dir lassen kannst. Mein Ziel ist es, DIR dabei zu helfen, den Spaß am Yoga zu entdecken und DEINE Gedanken zur Ruhe kommen zu lassen.</p>
<Slide triggerOnce duration={2000}>
<h2>INKLUSION</h2>
</Slide>


<p>Ich möchte DIR zeigen, dass Yoga für alle Körper – einschließlich Plus-Size-Körper – geeignet ist. Ich möchte inklusive Umgebung schaffen, in der jeder Mensch die positiven Wirkungen von Yoga erfahren kann, unabhängig von Größe oder Form. Durch achtsame und angepasste Praktiken strebe ich danach, dass DEIN Wohlbefinden und DEINE Selbstakzeptanz zu fördern. </p>
<Slide triggerOnce duration={2000}>
<h2>KREATIVITÄT</h2>
</Slide>

<p>Ich strebe nach Vielfalt und Abwechslung, indem ich meine Yogastunden mit einer Mischung aus traditionellen Asanas und kreativen Posen gestalte. Durch diese Kombination möchte ich DICH dazu ermutigen, DICH auszuprobieren, DEINE Grenzen zu erweitern und DEINEN eigenen Ausdruck im Yoga zu finden. </p>

<Slide triggerOnce duration={2000}>
<h2>ENTWICKLUNG</h2>
</Slide>

<p>In meinem Unterricht geht es nicht nur um das körperliche Training, sondern auch um die ganzheitliche Entwicklung jedes Einzelnen. Mein Ziel ist es, DIR zu helfen, DEINE innere Ruhe zu finden, DEINE Gesundheit zu fördern und eine positive Veränderung in DEINEM Leben zu erleben. Mit Leidenschaft, Empathie und einem respektvollen Umgang begleite ich DICH auf DEINER Yogareise und stehe DIR dabei zur Seite, DEIN volles Potenzial zu entfalten. </p>

            </p>
          </Col>
          <Col md={4} className="myAvtar">
              <img src={myImg} className="img-fluid" alt="Side bend" />
              <img src={myImg2} className="img-fluid" alt="Wheel" />
              <img src={myImg3} className="img-fluid" alt="Gate" />
          </Col>
        </Row>
        <Row>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
