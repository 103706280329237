import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";


function AGB() {
  return (
    <section>
    <Particle />
    <Container fluid="true" className="ds-about-description">
        <Container className="imp-about-section">
          <Row>
            <Col>
            <h1>Allgemeine Geschäftsbedingungen (AGB) von</h1>
              <p>
              The Mermaid Yoga e.U. <br></br>
              Bianca Bleicher<br></br>
              Wilhelm Lebsaft-Gasse 2a/2<br></br>
              3400 Klosterneuburg<br></br>
              (im folgenden „The Mermaid Yoga“ genannt)<br></br>
              </p>
              <h2>ALLGEMEIN</h2>
              <p>Gegenstand dieser Geschäftsbedingungen sind alle Angebote, Dienstleistungen und digitale Produkte welche
              auf der Website von The Mermaid Yoga angeboten werden. Mit der Nutzung eines Angebotes oder einer
              Dienstleistung akzeptiert die kaufende Person diese Geschäftsbedingungen vorbehaltlos.</p>
              <p>The Mermaid Yoga bietet Unterricht im Rahmen von Kursen, Online-Kursen, Seminaren, Workshops und
              Privatstunden sowie Yoga-bezogene digitale Produkte an.</p>
              <p>Gemäß der EU-Geoblocking-Verordnung erfolgt keine Diskriminierung nach Staatsangehörigkeit, Wohnsitz oder Niederlassung. Der Vertragsabschluss ist unabhängig von Wohnsitz, der Niederlassung oder der Staatsangehörigkeit der kaufenden Person möglich. Es gelten dieselben Preise und Bedingungen für kaufende Personen aus allen EU-Staaten. Die Vertragssprache sowie die Sprache aller Yoga-Kurse (vor Ort und online) ist Deutsch.</p>
              <p>Diese AGB sind ausdruck- und speicherbar. Die Druckfunktion bzw. Speicherfunktion des Browsers ist zu verwenden.</p>
              <h2>NUTZUNGSRECHT</h2>
              <p>Alle Leistungen von The Mermaid Yoga stehen grundsätzlich allen Interessierten offen. Ein Rechtsanspruch auf
              die Inanspruchnahme von Leistungen besteht jedoch nicht. Sofern für eine bestimmte Leistung besondere
              persönliche Bedingungen vorausgesetzt sind, müssen diese von den kaufenden Personen erfüllt werden. The
              Mermaid Yoga behält sich vor, Interessierte vor Erbringung der Leistung ohne Angabe von Gründen abzulehnen
              bzw. das Vertragsverhältnis wegen Nichterfüllung von Teilnahmevoraussetzungen nachträglich zu kündigen.</p>
              <h2>EU-STREITSCHLICHTUNG</h2>
<p>Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten
wir Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.
Verbraucher haben die Möglichkeit, Beschwerden an die OnlineStreitbeilegungsplattform der EU zu richten: <a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a><br/>
Zur Teilnahme an Streitschlichtungsverfahren vor einer Verbraucherschlichtungss telle sind wir weder
bereit noch verpflichtet.</p>
<p>Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an
Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
              <h2>BEDINGUNGEN FÜR DEN YOGAUNTERRICHT</h2>
              <p>Folgende Bedingungen gelten für Yogastunden, Online-Kurse, Kurse, Seminare, Workshops und Privatstunden,
              die von The Mermaid Yoga durchgeführt werden.</p>
              <h3>Voraussetzungen</h3>
              <p>Die kaufenden Personen erklären verbindlich mit ihrer Anmeldung zu Yogakursen, dass sie physisch gesund
              und psychisch stabil, sowie den einschlägigen körperlichen und geistigen Anforderungen gewachsen ist. Die
              angebotenen Leistungen verstehen sich nicht als Therapie- oder Heilprogramm. Sie ersetzen in keiner Weise eine
              ärztliche Versorgung oder Verordnung von Medikamenten. Im Zweifelsfall ist eine Ärztin zu konsultieren.</p>
              <h3>Gesundheitszustand & Haftungsausschluss der kaufenden Personen</h3>
              <p>Die kaufenden Personen nehmen die nachstehenden Hinweise und Trainingsbedingungen in rechtlich bindender
              Weise zur Kenntnis.</p>
              <ol type="1">
              <li>Die kaufenden Personen haben vor dem Yoga Training dem Lehrpersonal alle Verletzungen und Operationen
              der letzten Jahre oder chronische bzw. akute gesundheitliche Probleme (vor allem Wirbelsäule), bekanntgegeben,
              damit im Unterricht darauf Rücksicht genommen werden kann. Auch Schwangerschaft ist jeweils vor
              Stundenbeginn unbedingt bekanntzugeben.</li>
              <li>Die kaufenden Personen bestätigen, dass sie sich innerhalb der letzten sechs Monate vor Beginn des Yoga
              Trainings einer ärztlichen Kontrolle unterzogen haben, wobei diese ergeben hat, dass keine medizinischen sowie
              gesundheitlichen Bedenken gegen das beabsichtigte Yogatraining sowie die hierbei vorgesehenen Übungen
              bestehen.</li>
              <li>Die kaufenden Personen nehmen zur Kenntnis, dass alle im Rahmen des Yoga Trainings stattfindenden
              Übungen grundsätzlich auf eigenes Risiko durchgeführt werden und somit für allfällige Verletzungen oder
              gesundheitlich nachteilige Folgen keine wie immer geartete Haftung übernommen wird.</li>
              <li>Die kaufenden Personen verpflichten sich ausdrücklich, allfällige im Rahmen des Trainings eintretende
              gesundheitliche Beeinträchtigungen unverzüglich dem Lehrpersonal bekannt zu geben und schriftlich zu
              dokumentieren, damit das Training in diesem Fall rechtzeitig und sachgerecht geändert werden kann. Bei
              Nichteinhaltung dieser Verpflichtung ist ebenfalls jegliche Haftung des Lehrpersonals ausgeschlossen.</li>

              <li>Während des Yoga Trainings wird die kaufende Person den Instruktionen aufmerksam Folge leisten und die
              Übungen entsprechend ausführen, ohne dabei über ihre Grenzen zu gehen. Die kaufende Person wird laut
              Lehrpersonal die Positionen adaptieren oder um alternative Positionen bitten. Sollte die kaufende Person eine
              Instruktion nicht verstanden haben oder die Übung aus sonstigen Gründen nicht ausführen können, wird sie die
              Übung auslassen und nach Möglichkeit bei der folgenden Übung wieder teilnehmen. </li>
              </ol>
              <h3>An- und Abmeldung</h3>
              <p>Die Registrierung für alle Stunden erfolgt auf der Website von The Mermaid Yoga über das Buchungstool
              Fyndery.</p>
              <p>Folgende Schritte müssen für den Buchungsvorgang auf Fyndery getätigt werden: 
              <li>
                Auswahl des gewünschten Yogakurses</li> 
                <li>Auswahl des gewünschten Termins und Klick auf "Termin auswählen"</li> 
                <li>Auswahl des gewünschten Tickets</li> 
                <li>Klick auf "Jetzt buchen"</li> 
                <li>Eingabe der persönlichen Daten für die Buchung</li> 
                <li>Falls vorhanden Eingabe eines Gutscheincodes</li> 
                <li>Akzeptieren der AGB, Wiederrufsbelehrung und Datenschutzhinweise von Fyndery und The Mermaid Yoga</li> 
                <li>Prüfung der Buchung und ggf. Berichtigung der eingegebenen Daten</li> 
                <li>Auswahl der gewünschten Zahlungsmethode</li> 
                <li>Verbindliche Absendung der Buchung durch Klick auf "Jetzt bezahlen"</li> 
                <li>Erhalt der Buchungsbestätigung inklusive Rechnung via Mail</li> 
                <br/>

Fyndery speichert den Vertragstext einschließlich der AGB bei Vertragsschluss unter Wahrung des Datenschutzes und sendet diese der kaufenden Person nach Absendung von dessen Bestellung in Schrift- oder Textform (per E-Mail) zu. Eine darüber hinausgehende Zugänglichmachung des Vertragstextes durch Fyndery erfolgt nicht.

Den Vertragstext kann die kaufende Person in ihrem Kundenkonto auf der Website von Fyndery einsehen, sofern sie vor Absendung der Bestellung ein Kundenkonto eingerichtet hat. Die Bestelldaten werden im System von Fyndery gespeichert und können unter Verwendung der Zugangsdaten im passwortgeschützten Kundenkonto eingesehen und abgerufen werden.

The Mermaid Yoga erhält nach die Informationen zu getätigten Buchungen via E-Mail und kann die Buchungen im Partnerbereich von Fyndery einsehen.</p>
              <p>Die Anmeldungen werden in der Reihe ihres Eingangs berücksichtigt. Ist eine Stunde bereits voll besetzt, gibt
              es über das Buchungstool die Möglichkeit, sich auf eine Warteliste setzen zu lassen. Bei Stornierung einer Buchung
              wird der/die nächste InteressentIn aus der Warteliste informiert.</p>
             
              <p>Blockkurse sind Kurse mit einer begrenzten Dauer. Die Dauer wird je nach Kurs  durch die Anzahl der Termine definiert (z.B. Anfängerkurs mit 6 Yoga-Einheiten). Für die Teilnahme an einem Blockkurs wird ein einmaliger Kursbeitrag bezahlt. Die Einheiten der Blockkurse bauen aufeinander auf. Versäumte Einheiten können nicht nachgeholt werden. Ebenso wird im Falle von versäumten Einheiten kein Geld zurückerstattet. 
              </p>
              <p>Die Zahlung der Kurse erfolgt direkt nach der Anmeldung über das Buchungstool Fyndery und ist per PayPal,
              Kreditkarte oder Sofortüberweisung möglich.</p>
              <p>Für Einzelstunden bestehen folgende Stornierungsrichtlinien:
                <li>Reservierungen, welche bis zu 24 Stunden vor Beginn der Yogastunde storniert werden, werden gänzlich
                zurückerstattet. </li>
                <li>Reservierungen, welche bis zu 12 Stunden vor Beginn der Yogastunde storniert werden, werden zur Hälfte
                zurückerstattet.</li>
                <li>Reservierungen, die weniger als 6 Stunden vor Beginn der Yogastunde storniert werden, werden gänzlich
                verrechnet.</li>
              </p>
              <p>Für Blockkurse gelten folgende Stornierungsrichtlinien:
                <li>Reservierungen, welche mehr als 72 Stunden vor Beginn des Kurses storniert werden, werden gänzlich
                zurückerstattet.</li>
                <li>Reservierungen, welche bis zu 72 Stunden vor Beginn des Kurses storniert werden, werden zur Hälfte
                zurückerstattet.</li>
                <li>Reservierungen, welche weniger als 24 Stunden vor Beginn des Kurses storniert werden, werden gänzlich
                verrechnet.</li>
              </p>
              <p>Für Privatstunden gelten folgende Stornierungsrichtlinien:
              <li>Privatstunden, welche weniger als 24 Stunden vor dem vereinbarten Termin storniert werden, werden gänzlich
              verrechnet.</li>
              </p>
              <p>Ein/e ErsatzteilnehmerIn kann zu jedem Zeitpunkt gestellt werden, und dies ersetzt die Stornogebühr. </p>
              <p>Es besteht kein Rechtsanspruch auf Verlängerung oder Rückerstattung der Gebühren von Kursen bei Erkrankung.</p>

              <h3>Preise und Preisänderung</h3>
              <p>Die Dienstleistungen und Produkte von The Mermaid Yoga sind umsatzsteuerbefreit aufgrund der Kleinunternehmerregelung gem. § 6 Abs. 1 Z 27 UstG. Die Preise verstehen sich inklusive sonstiger Abgaben, Zuschläge oder Verstandkosten.</p>
              <p>The Mermaid Yoga behält sich vor, die geltenden Preise zu verändern. Die Preisänderung wird wirksam, wenn
              sie mindestens zwei Wochen zuvor durch Ankündigung auf der Webseite bekannt gegeben werden. Bereits
              erworbene Karten und Mitgliedschaftsverträge behalten ihre Gültigkeit zum vereinbarten Preis bis zum Ablauf der
              Vertragslaufzeit.</p>
              <h3>Eintreffen vor Yogaeinheiten</h3>
              <p>Das Lehrpersonal ist 10 Minuten vor der Einheit anwesend. Die Türe zu den Yogaräumlichkeiten wird mit
              Beginn der Yogaeinheit pünktlich verschlossen, damit die Yogaeinheit ungestört verlaufen kann. Um ein
              rechtzeitiges Eintreffen wird daher gebeten. Für Zuspätkommende besteht kein Anspruch auf Teilnahme an einer
              bereits laufenden Einheit.</p>
              <h3>Teilnahmeobliegenheit, Ausschluss von Yogaeinheiten</h3>
              <p>Die kaufenden Personen sind zur regelmäßigen und konstruktiven Teilnahme an den jeweiligen Yogastunden
              aufgerufen. Absenzen liegen ausnahmslos im Verantwortungsbereich der kaufenden Personen und begründen
              keinen Anspruch auf Rückerstattung von Teilnahmegebühren.</p>
              <p>Während des Aufenthalts am Standort, an welchem die Yogastunden stattfinden, findet die Hausordnung des
              jeweiligen Standortes Anwendung. Die kaufende Person hat sich in den Räumlichkeiten, in welchen die
              Yogastunden stattfinden, stets so zu verhalten, dass es zu keiner Störung des Ablaufes der Stunden kommt, die
              Einrichtung des Studios pfleglich behandelt wird und es zu keiner Beeinträchtigung der anderen Teilnehmer
              kommt. Während einer Stunde ist absolute Ruhe einzuhalten und jegliche Störung, insbesondere durch Geräte, zu
              vermeiden.</p>
              <p>The Mermaid Yoga behält sich vor, kaufende Personen in besonderen Fällen, z.B. wegen nachhaltiger Störung,
              von der Kursteilnahme auszuschließen. Für diesen Fall erfolgt keine Erstattung von Teilnahmegebühren. Ein ggf.
              zu verantwortender Schaden ist von der betroffenen kaufenden Person zu ersetzen.</p>

              <h3>Absage, Abbruch, Änderungen</h3>
              <p>The Mermaid Yoga behält sich vor, eine Kurseinheit bzw. einen ganzen Kurs aus wichtigem Grund kurzfristig
              zu verschieben, zu unterbrechen oder ausfallen zu lassen.</p>
              <p>The Mermaid Yoga behält sich das Recht auf Sperrzeiten vor (Weihnachtspause und Sommerpause). In den
              Sperrzeiten finden keine Yogastunden statt.</p>
              <p>The Mermaid Yoga weist darauf hin, dass Inhalt und Ablauf von Kursen oder der Yogalehrerin bei Erfordernis
              kurzfristig – bei grundsätzlicher Wahrung des Gesamtcharakters des Kurses – geändert werden kann. Dies
              berechtigt die kaufende Person nicht zum Rücktritt vom Vertrag. Ein Schadenersatzanspruch wird ausgeschlossen.
              </p>
              <p>Bei einer zu geringen TeilnehmerInnenzahl kann The Mermaid Yoga eine Kursabsage vornehmen. Die
              kaufenden Personen werden über eine etwaige Kursabsage rechtzeitig vor Yogaeinheit telefonisch oder per Mail
              informiert.</p>
              <h3>Onlinekurse</h3>

              <p>The Mermaid Yoga nutzt zur Online-Übertragung von Online Live Yogastunden die Software Teams. Zur
Teilnahme an diesen Stunden wird der Download der dazu nötigen Software oder App auf einem Laptop, Tablet
oder Smartphone empfohlen, jedoch ist auch eine Teilnahme via Browser möglich. In diesem Zusammenhang
gelten die Geschäftsbedingungen und die Datenverarbeitungsrichtlinien von Teams. Alle TeilnehmerInnen können
die Video-Übertragung anderer TeilnehmerInnen sehen. Die Kamerafunktion kann jedoch deaktiviert oder die
Kamera abgedeckt werden. Der Name, der beim Betreten des Online-Meeting-Raums angegeben wird, wird
anderen TeilnehmerInnen angezeigt. Es können aber beispielsweise auch nur die Initialien angegeben werden. </p>
<p>Die TeilnehmerInnen sind verpflichtet, den Zugangslink für die Online Live Yogastunden geheim zu halten
sowie die unberechtigte Nutzung des Links durch Dritte zu verhindern. Ebenso ist die Aufnahme von Online
Yogastunden, die Verteilung und Vervielfachung verboten.</p>
<p>Bei der Teilnahme an einer Online Live Yogastunde sind die TeilnehmerInnen verpflichtet, die technischen
Voraussetzungen für die Kursteilnahme zu schaffen (stabile Internet-Verbindung, aktuelle Browserversion, ggf. das
Herunterladen des notwendigen Programmes etc.) und zu testen.
Sollte ein etwaiger technischer Ausfall in den Verantwortungsbereich des/der TeilnehmerIn fallen, entbindet dies
nicht von der vertraglichen Zahlungspflicht.
Sofern der/die TeilnehmerIn während der Online Yogastunde keine technischen Probleme mitteilt, gilt die
Teilnahme als erfolgt.</p>

              <h2>VERSCHWIEGENHEIT, DATENSCHUTZ</h2>
              <p>Personen- und firmenbezogene kaufenden Personen-Daten werden unter Berücksichtigung der DSGVO
              (Datenschutzgrundverordnung) erhoben, gespeichert und verarbeitet. Alle Details zur Umsetzung finden Sie in den
                 Datenschutzbestimmungen von The Mermaid Yoga. Im Zuge der Teilnahme am Yogaunterricht
              bekannt gewordene vertrauliche Informationen der kaufenden Personen werden streng vertraulich behandelt.</p>
              <h2>ZUSTIMMUNGSERKLÄRUNG</h2>
              <p>Durch das Setzen des Häkchens im Zuge einer Onlinebuchung bei Fyndery bestätigt die kaufende Person, dass
              die Allgemeinen Geschäftsbedingungen von The Mermaid Yoga gelesen wurden.</p>
              <h2>VERWEISE UND LINKS</h2>
              <p>Bei direkten oder indirekten Verweisen auf fremde Internetseiten (“Links”), die außerhalb des
              Verantwortungsbereiches von The Mermaid Yoga liegen, würde eine Haftungsverpflichtung ausschließlich in dem
              Fall in Kraft treten, in dem The Mermaid Yoga von den Inhalten Kenntnis hat und es technisch möglich und
              zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern. The Mermaid Yoga erklärt hiermit
              ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar
              waren. Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der gelinkten/verknüpften
              Seiten hat The Mermaid Yoga keinerlei Einfluss. Deshalb distanziert sich The Mermaid Yoga hiermit ausdrücklich
              von allen Inhalten aller gelinkten/verknüpften Seiten, die nach der Linksetzung verändert wurden. Diese
              Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise und Mailinglisten.
              Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder
              Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf welche
              verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.</p>
              <h2>SCHLUSSBESTIMMUNGEN</h2>
              <p>Nebenabreden bestehen nicht. Änderungen bedürfen der Schriftform. Dies gilt auch für die Schriftformklausel.
              Die Berichtigung von Irrtümern sowie von Druck- und Rechenfehlern bleibt vorbehalten.</p>
              <p>Sollten einzelne Bestimmungen der Allgemeinen Geschäfts- und Teilnahmebedingungen ganz oder teilweise
              unwirksam sein oder werden, so wird hierdurch die Gültigkeit der übrigen Bestimmungen nicht berührt
              (Salvatorische Klausel). An Stelle der ganz oder teilweise unwirksamen Regelung gilt, soweit rechtlich zulässig,
              eine andere angemessene Regelung, welche wirtschaftlich im Sinn und Zweck der ursprünglichen Bestimmung am
              nächsten kommt.</p>

            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default AGB;
