import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Particle from "../Particle";
import {Link} from "react-router-dom";
import blog1 from "../../Assets/Blog8_Foto1.webp";
import blog2 from "../../Assets/Blog8_Foto2.webp";
import blog3 from "../../Assets/Blog8_Foto3.webp";
import blog4 from "../../Assets/Blog8_Foto4.webp";
import blog5 from "../../Assets/Blog8_Foto5.webp";
import blog6 from "../../Assets/Blog8_Foto6.webp";
import blog7 from "../../Assets/Blog8_Foto7.webp";
import blog9 from "../../Assets/Blog8_Foto9.webp";

function BlogYoga8() {
  return (
    <Container fluid="true" className="blog-section" id="Blog">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "0px",
            }}
          >
          </Col>
          <Col md={12} style={{
            paddingTop: "20px",
              justifyContent: "center" }}>
        </Col>
        </Row>
        
        <Row>
        <Card className="blog-card" style={{ backgroundColor: 'transparent' }}>
            <Card.Body>
                <Card.Text>
                
                <h1>Dem Rücken Liebe schenken: Die Bewegungsrichtungen der Wirbelsäule</h1>

<p>Die Wirbelsäule, auch als Rückgrat bekannt, ist eine der fundamentalsten Strukturen im menschlichen Körper. Sie besteht aus einer Reihe von miteinander verbundenen Knochen, den Wirbeln, die nicht nur als Stütze dienen, sondern auch Bewegung ermöglichen. Die Wirbelsäule ist nicht nur das physische Rückgrat unseres Körpers, sondern auch ein Symbol für unsere innere Ausrichtung und Flexibilität. Im Yoga werden die Bewegungen der Wirbelsäule als grundlegende Elemente betrachtet, um körperliche, mentale und spirituelle Harmonie zu erreichen. In diesem Blogpost werden wir die Bewegungsrichtungen der Wirbelsäule im Yoga erforschen, uns also mit Vorwärtsbeugen, Seitbeugen, Twists und Rückbeugen auseinandersetzen und verstehen, wie jede dieser Asana-Gruppen unseren Körper und Geist beeinflusst und was man bei deren Durchführung beachten sollte.</p>

<p>Wenn du noch mehr über die Bewegungsrichtungen der Wirbelsäule und die dazugehörigen Asana-Gruppen lernen möchtest, dann habe ich den passenden Yogakurs für dich. Mein „Liebe für den Rücken“ Kurs besteht aus vier einstündigen Einheiten, in welchen wir uns Vorbeugen, Seitbeugen, Twists und Rückbeugen in der Praxis näher ansehen. Für den nächsten Kurs kannst du dich <Link className="purpledark" as={Link} to="https://www.fyndery.de/coach/14566/bianca/#courses_tab">
               hier
        </Link> anmelden.</p>

        <br></br>
<h2>Die Bedeutung der Wirbelsäule im Yoga</h2>

<p>In der Praxis des Yoga wird die Wirbelsäule oft als Sitz der Lebensenergie betrachtet. Sie ist die physische Achse unseres Körpers und besteht aus einer Reihe von miteinander verbundenen Wirbeln, die Flexibilität und Stabilität ermöglichen. Im Yoga wird sie oft als "Sushumna", der zentrale Energiekanal, angesehen, der es ermöglicht, dass Prana, die vitale Lebensenergie, durch den Körper fließt.</p>

<p>Die Wirbelsäule ist in der Yoga-Praxis von entscheidender Bedeutung, da sie die Basis für viele Asanas bildet und eine zentrale Rolle bei der Ausrichtung des Körpers spielt. Durch gezielte Yoga-Übungen können wir die Flexibilität und Stärke der Wirbelsäule verbessern, was nicht nur zu einem besseren körperlichen Wohlbefinden beiträgt, sondern auch zu einem gesteigerten Energiefluss. </p>

<p>Eine gesunde Wirbelsäule ist entscheidend für die Gesamtheit unseres Wohlbefindens, da sie nicht nur die Struktur des Körpers unterstützt, sondern auch das Nervensystem schützt und den Fluss von Informationen zwischen Gehirn und Körper ermöglicht. Durch die Praxis von Asanas die darauf abzielen, die Wirbelsäule zu mobilisieren, zu stärken und auszurichten, können wir Rückenschmerzen lindern, die Haltung verbessern und das allgemeine Wohlbefinden steigern. </p>

<p>Im Yoga werden verschiedene Bewegungsrichtungen der Wirbelsäule betont, darunter Vorwärtsbeugen, Seitbeugen, Twists und Rückbeugen. Jede dieser Bewegungen hat ihre eigenen einzigartigen Vorteile und trägt dazu bei, die Flexibilität und Stärke der Wirbelsäule zu verbessern. Durch die bewusste Praxis dieser Asanas können wir nicht nur körperliche Beschwerden lindern, sondern auch unseren Geist beruhigen, unser Bewusstsein erweitern und eine tiefere Verbindung zu uns selbst herstellen. </p>


<br></br>
<h2>Vorwärtsbeugen: Eine Reise zur inneren Ruhe</h2>

<p>Vorwärtsbeugen sind nicht nur eine physische Bewegung, sondern auch eine Einladung zur inneren Reflexion und Entspannung. Wenn wir uns in eine Vorwärtsbeuge begeben, wie Uttanasana (stehende Vorwärtsbeuge) oder Paschimottanasana (sitzende Vorwärtsbeuge), neigen wir uns nach vorne, um uns mit unserer inneren Welt zu verbinden.</p>

<img src={blog1} alt="Sitzende Vorwärtsbeuge" style={{ maxWidth: '90%' , maxHeight: '500px', paddingBottom: '20px', paddingTop: '20px' }} />

<h3>Physische Wirkung</h3>

<p>Auf körperlicher Ebene wirken Vorwärtsbeugen folgendermaßen:</p>

<p><li><b>Dehnung der Rückenmuskulatur:</b>Während einer Vorwärtsbeuge wird die Muskulatur entlang der gesamten Wirbelsäule gedehnt, von den unteren bis zu den oberen Rückenmuskeln. Dies trägt dazu bei, Verspannungen und Steifheit zu reduzieren, insbesondere in Bereichen, die durch langes Sitzen oder Stehen belastet sind. </li></p>

<p><li><b>Öffnung der Hüftgelenke: </b> Vorwärtsbeugen involvieren auch die Hüftgelenke, wodurch die Flexibilität in diesem Bereich erhöht wird. Die Dehnung der Hüftbeuger kann helfen, Schmerzen im unteren Rücken zu lindern und die Beweglichkeit beim Gehen und anderen Aktivitäten zu verbessern. </li></p>

<p><li><b>Streckung der Beinmuskulatur: </b> Während einer Vorwärtsbeuge werden auch die Muskeln an der Rückseite der Beine, einschließlich der Oberschenkelrückseiten und Waden, gedehnt. Dies hilft, die Flexibilität der Beine zu verbessern und kann dazu beitragen, Schmerzen oder Beschwerden in diesem Bereich zu reduzieren. </li></p>

<p><li><b>Stimulation der Bauchorgane: </b>Die Kompression des Bauchraums während einer Vorwärtsbeuge kann die Bauchorgane massieren und die Verdauung anregen. Dies kann dazu beitragen, Verdauungsbeschwerden zu lindern und die Funktion des Magen-Darm-Trakts zu verbessern. </li></p>

<p><li><b>Entlastung der Wirbelsäule: </b> Durch das Nach-vorne-Neigen in einer Vorwärtsbeuge wird die Wirbelsäule entlastet, da die Schwerkraft die Wirbel voneinander trennt. Dies kann helfen, Druck von den Bandscheiben zu nehmen und Rückenschmerzen zu lindern. </li></p>

<p>Zusätzlich zu diesen physischen Vorteilen haben Vorwärtsbeugen auch eine beruhigende Wirkung auf das Nervensystem, was zu einem Gefühl der Entspannung und des Loslassens führen kann. Es ist jedoch wichtig, Vorwärtsbeugen mit Achtsamkeit auszuführen und darauf zu achten, die Übung entsprechend den eigenen Grenzen und Bedürfnissen anzupassen, insbesondere wenn Rückenprobleme vorliegen.</p>

<img src={blog2} alt="Stehende Vorwärtsbeuge" style={{ maxWidth: '90%' , maxHeight: '500px', paddingBottom: '20px', paddingTop: '20px' }} />

<h3>Mentale Wirkung</h3>

<p>Diese Asanas laden dazu ein, in die Stille zu tauchen und den Fokus nach innen zu lenken. Während wir uns nach vorne beugen, können wir uns bewusst werden, was wir loslassen müssen, sei es körperliche Spannungen oder mentale Belastungen. Die ruhige Natur der Vorwärtsbeugen hilft, den Geist zu beruhigen und Stress abzubauen. Zudem unterstützen sie dabei, die Atmung zu vertiefen, was eine beruhigende Wirkung auf das Nervensystem hat.</p>

<h3>Was es zu beachten gilt</h3>

<p>Bei der Ausführung von Vorwärtsbeugen ist es wichtig, aus der Hüfte heraus zu beugen und die Wirbelsäule lang zu halten. Vermeide es, die Beine zu überstrecken, und achte darauf, dass der Atem frei fließen kann. Diese Asanas können anfangs herausfordernd sein, besonders für Menschen mit Rückenproblemen, daher ist es wichtig, sanft zu beginnen und sich langsam zu dehnen. </p>

<br></br>
<h2>Seitbeugen: Die Kunst der Öffnung und Expansion</h2>

<p>Seitbeugen sind eine tolle Möglichkeit, um den Körper seitlich zu öffnen und Platz für neues Wachstum und neue Perspektiven zu schaffen. In Asanas wie Parsva Sukhasana (Sitzende Seitbeuge) oder Parighasana (Torposition) kommen wir in den Genuss der seitlichen Dehnung und Ausdehnung.</p>

<img src={blog3} alt="Sitzende Drehung" style={{ maxWidth: '90%' , maxHeight: '500px', paddingBottom: '20px', paddingTop: '20px' }} />


<h3>Physische Wirkung</h3>

<p>Seitbeugen bieten eine Vielzahl physischer Vorteile und können eine erfrischende und belebende Wirkung auf den Körper haben. Hier sind einige der Hauptwirkungen von Seitbeugen auf den Körper:</p>

<p><li><b>Dehnung der seitlichen Rumpfmuskulatur: </b> Seitbeugen strecken die Muskeln entlang der Seiten des Rumpfes, einschließlich der seitlichen Bauchmuskeln (Obliques), der Interkostalmuskeln zwischen den Rippen und der seitlichen Rückenmuskulatur. Diese Dehnung hilft, Spannungen in diesen Bereichen zu lösen und die Flexibilität der seitlichen Rumpfmuskulatur zu verbessern. </li></p>

<p><li><b>Streckung der seitlichen Wirbelsäule: </b> Seitbeugen zielen darauf ab, die Wirbelsäule seitlich zu strecken, was die Flexibilität der Wirbelsäule insgesamt verbessert. Diese Streckung kann helfen, Verklebungen zwischen den Wirbeln zu lösen und die Mobilität der Wirbelsäule zu erhöhen. </li></p>

<p><li><b>Verbesserung der seitlichen Flexibilität: </b>Durch regelmäßiges Praktizieren von Seitbeugen kann die seitliche Flexibilität des Körpers erhöht werden, was insbesondere bei Aktivitäten wie Tanzen, Laufen oder seitlichem Bücken von Vorteil sein kann. </li></p>

<p><li><b>Stimulation der Bauchorgane: </b>Ähnlich wie bei Vorwärtsbeugen kann auch das Komprimieren des Bauchraums während einer Seitbeuge die Bauchorgane massieren und die Verdauung anregen. Dies kann dazu beitragen, Verdauungsbeschwerden zu lindern und die Funktion des Magen-Darm-Trakts zu verbessern. </li></p>

<p>Zusätzlich zu diesen physischen Vorteilen können Seitbeugen auch dazu beitragen, Stress und Spannungen zu reduzieren, da sie eine beruhigende Wirkung auf das Nervensystem haben. Sie fördern auch eine bessere Körperwahrnehmung und Achtsamkeit, indem sie uns dazu ermutigen, auf die Empfindungen in unserem Körper zu achten und sanft mit unseren Grenzen umzugehen. </p>

<img src={blog4} alt="Gate Pose" style={{ maxWidth: '90%' , maxHeight: '500px', paddingBottom: '20px', paddingTop: '20px' }} />

<h3>Mentale Wirkung</h3>

<p>Seitbeugen ermutigen uns, uns für neue Möglichkeiten zu öffnen und alte Begrenzungen loszulassen. Indem wir uns seitlich beugen, können wir alte Gewohnheiten und Denkmuster hinter uns lassen und uns für frische Ideen und Inspirationen öffnen. Seitbeugen können auch dazu beitragen, das Herz zu öffnen und Mitgefühl für uns selbst und andere zu kultivieren. Die seitliche Dehnung ermöglicht auch eine verstärkte Atmung, die den Geist klarer und ruhiger macht. </p>

<h3>Was es zu beachten gilt</h3>

<p>Achte darauf, dass du gleichmäßig in beide Seiten streckst und nicht nur auf eine Seite sinkst. Halte die Schultern entspannt und den oberen Arm aktiv, um die seitliche Dehnung zu vertiefen. Wenn du Schwierigkeiten hast, dich seitlich zu beugen, kannst du auch einen Block oder eine Stuhllehne verwenden, um die Dehnung zu unterstützen. Es ist wichtig, die seitlichen Bewegungen langsam und bewusst durchzuführen, um Verletzungen zu vermeiden und die gesamte Seitenlänge zu dehnen. </p>

<br></br>
<h2>Twists: Die Reinigung von Körper und Geist</h2>

<p>Twists sind eine kraftvolle Möglichkeit, um den Körper zu reinigen und den Geist zu klären. In Asanas wie Revolved Utkatasana (Drehung in der Stuhl Position) und Marichyasana C (Sitzende Drehung Variante) erfahren wir die transformative Kraft der Drehbewegungen. </p>

<img src={blog5} alt="Drehung im Stuhl" style={{ maxWidth: '90%' , maxHeight: '500px', paddingBottom: '20px', paddingTop: '20px' }} />

<h3>Physische Wirkung</h3>

<p>Twists wirken auf den Körper folgendermaßen: </p>

<p><li><b>Streckung der Wirbelsäule: </b> Twists beinhalten eine Drehbewegung der Wirbelsäule, die dazu beiträgt, sie zu strecken und zu mobilisieren. Diese Streckung kann helfen, die Flexibilität der Wirbelsäule zu verbessern und die Beweglichkeit zwischen den Wirbeln zu erhöhen. </li></p>

<p><li><b>Stärkung der Rückenmuskulatur: </b>Während einer Twist-Asana werden die Muskeln entlang der Wirbelsäule, einschließlich der paravertebralen Muskeln, aktiviert und gestärkt. Dies hilft, die Stabilität der Wirbelsäule zu verbessern und Rückenschmerzen vorzubeugen. </li></p>

<p><li><b>Stimulation der Bauchorgane: </b>Durch die Verdrehung des Oberkörpers werden die Bauchorgane wie Magen, Leber und Nieren sanft massiert. Dies kann die Verdauung anregen, indem die Durchblutung und die Funktion dieser Organe verbessert werden. </li></p>

<p><li><b>Entgiftung des Körpers: </b>Twists können helfen, Giftstoffe aus dem Körper freizusetzen, indem sie die Bauchorgane stimulieren und die Lymphdrainage unterstützen. Dies kann dazu beitragen, den Körper zu entgiften und die Gesundheit zu verbessern. </li></p>

<p><li><b>Verbesserung der Haltung: </b> Durch das Stärken der Rückenmuskulatur und das Strecken der Wirbelsäule können Twists dazu beitragen, die Haltung zu verbessern und Haltungsfehler zu korrigieren. </li></p>

<p><li><b>Stärkung der seitlichen Bauchmuskulatur: </b> In vielen Twist-Asanas werden auch die seitlichen Bauchmuskeln (Obliques) aktiviert und gestärkt. Dies trägt dazu bei, die seitliche Stabilität des Rumpfes zu verbessern und die Taille zu straffen. </li></p>

<p> Twists fördern die Flexibilität, Stärke und Gesundheit der Wirbelsäule und des gesamten Körpers. Durch die regelmäßige Praxis von Twist-Asanas können wir nicht nur die körperliche Fitness verbessern, sondern auch die inneren Organe stimulieren und die Verdauung unterstützen. </p>

<img src={blog6} alt="Marichyasana" style={{ maxWidth: '90%' , maxHeight: '500px', paddingBottom: '20px', paddingTop: '20px' }} />

<h3>Mentale Wirkung</h3>

<p>Twists helfen, den Geist zu klären und negative Energien loszulassen. Durch die Drehbewegungen können wir uns von alten Mustern und Gedanken befreien und Platz für neue Perspektiven schaffen. Twists können auch dabei helfen, die Konzentration zu verbessern und den Geist zu fokussieren. Die Drehbewegungen erfordern oft ein hohes Maß an Aufmerksamkeit und Achtsamkeit, was den Geist beruhigt und zentriert. </p>

<h3>Was es zu beachten gilt</h3>

<p>Stelle sicher, dass die Drehung vor allem aus der Brustwirbelsäule heraus stattfindet und nicht aus dem unteren Rücken. Der untere Rücken mit seinen großen, sperrigen Wirbeln ist dazu da uns stabil zu halten, währenddessen die Brustwirbelsäule mit ihren kleineren Wirbeln für Bewegung ausgelegt ist. Vermeide es, den Atem zu blockieren, und achte darauf, dass die Schultern entspannt bleiben. Wichtig ist auch, dass die Drehbewegungen sanft und kontrolliert ausgeführt werden, um Verletzungen zu vermeiden und die gesamte Wirbelsäule zu mobilisieren. </p>

<br></br>
<h2>Rückbeugen: Eine Erweckung des Herzens</h2>

<p>Rückbeugen sind eine kraftvolle Möglichkeit, um das Herz zu öffnen und uns mit unserer inneren Stärke und Lebendigkeit zu verbinden. Asanas wie Bhujangasana (Kobrahaltung) und Ustrasana (Kamelhaltung) zählen zu den bekanntesten Rückbeugen. </p>

<img src={blog7} alt="Kamel" style={{ maxWidth: '90%' , maxHeight: '500px', paddingBottom: '20px', paddingTop: '20px' }} />

<h3>Physische Wirkung</h3>

<p>Hier sind einige der Hauptwirkungen von Rückbeugen auf den Körper: </p>

<p><li><b>Streckung der vorderen Körperseite: </b> Rückbeugen beinhalten eine Streckung der vorderen Körperseite, einschließlich der Bauchmuskulatur, der Hüftbeuger und der Brustmuskulatur. Diese Dehnung hilft, die Flexibilität im vorderen Körperbereich zu verbessern und die Haltung zu korrigieren, insbesondere bei Personen die viel Zeit im Sitzen verbringen. </li></p>

<p><li><b>Öffnung des Brustkorbs: </b> Rückbeugen öffnen den Brustkorb und erweitern die Lungenkapazität, was zu einer verbesserten Atmung und Sauerstoffversorgung des Körpers führt. Diese Öffnung des Brustkorbs kann auch helfen Spannungen in den Schultern und im oberen Rückenbereich zu reduzieren. </li></p>

<p><li><b>Stärkung des Rückens: </b> Während einer Rückbeuge-Asana werden die Muskeln entlang der Wirbelsäule, einschließlich der Rückenstrecker und der unteren Rückenmuskulatur, aktiviert und gestärkt. Dies hilft, die Stabilität der Wirbelsäule zu verbessern und Rückenschmerzen vorzubeugen. </li></p>

<p><li><b>Stimulation der Bauchorgane: </b> Durch das Strecken und Öffnen des Bauchraums während einer Rückbeuge-Asana werden die Bauchorgane sanft massiert. Dies kann die Verdauung anregen und die Funktion der inneren Organe verbessern. </li></p>

<p><li><b>Verbesserung der Haltung: </b>Rückbeugen tragen dazu bei, die Haltung zu verbessern indem sie die Wirbelsäule strecken und aufrichten. Eine verbesserte Haltung kann nicht nur Rückenschmerzen reduzieren, sondern auch das Selbstbewusstsein steigern und das äußere Erscheinungsbild verbessern. </li></p>

<p><li><b>Stimmungsaufhellende Wirkung: </b>Rückbeugen können eine stimmungsaufhellende Wirkung haben, indem sie das Herz öffnen und das Energieniveau erhöhen. Dies kann dazu beitragen, Stress abzubauen und das allgemeine Wohlbefinden zu verbessern.</li></p>

<p> Durch die regelmäßige Praxis von Rückbeugen können wir nicht nur die körperliche Fitness verbessern, sondern auch die Atmung vertiefen, die Haltung korrigieren und die Stimmung heben. Es ist jedoch wichtig, Rückbeugen mit Achtsamkeit und Vorsicht auszuführen, insbesondere wenn bereits Rückenprobleme vorliegen. </p>

<img src={blog9} alt="Cobra" style={{ maxWidth: '90%' , maxHeight: '500px', paddingBottom: '20px', paddingTop: '20px' }} />

<h3>Mentale Wirkung</h3>

<p>Rückbeugen erwecken das Herz und verbinden uns mit unserem inneren Licht und unserer Lebensfreude. Durch das Öffnen der Brust können wir uns für Liebe und Mitgefühl öffnen, sowohl für uns selbst als auch für andere. Rückbeugen können auch dabei helfen, das Selbstvertrauen zu stärken und ein Gefühl von Mut und Entschlossenheit zu wecken. Die Natur der Rückbeugen kann auch dazu beitragen, die Stimmung zu verbessern und depressive Gedanken zu vertreiben. </p>

<h3>Was es zu beachten gilt</h3>

<p>Achte darauf, dass du die Rückbeugen aus der gesamten Wirbelsäule heraus durchführst und nicht nur aus dem unteren Rücken. Vermeide es, den Nacken zu überstrecken, und halte die Schultern entspannt. Wenn du Schwierigkeiten hast, dich nach hinten zu beugen, kannst du die Unterstützung von Yoga-Requisiten wie Blöcken oder Gurten in Betracht ziehen. Beginne langsam und achtsam mit Rückbeugen, um die Flexibilität der Wirbelsäule zu verbessern und das Vertrauen in deine Fähigkeiten zu stärken. </p>

<br></br>
<h2>Fazit</h2>

<p>Die Wirbelsäule ist von entscheidender Bedeutung für unsere Gesundheit und unser Wohlbefinden. Die Praxis der Yoga-Asanas, die darauf abzielen, die Wirbelsäule zu mobilisieren, zu stärken und auszurichten, kann eine transformative Wirkung auf unseren gesamten Körper haben. </p>

<p>Vorwärtsbeugen laden zur inneren Ruhe ein, Seitbeugen ermutigen zur Öffnung und Expansion, Twists reinigen Körper und Geist und Rückbeugen erwecken das Herz und verbinden uns mit unserer inneren Stärke und Lebendigkeit. Jede dieser Bewegungsrichtungen hat ihre eigenen einzigartigen Vorteile und trägt dazu bei, die Flexibilität und Stärke der Wirbelsäule zu verbessern. </p>

<p>Es ist jedoch wichtig, diese Asanas mit Achtsamkeit und Vorsicht auszuführen, insbesondere wenn bereits Rückenprobleme vorliegen. Indem wir auf unseren Körper hören, bewusst atmen und sanft mit unseren Grenzen umgehen, können wir die volle Wirkung der Asanas erfahren und unseren Rücken die Liebe und Fürsorge schenken, die er verdient. </p>

<p>Lasst uns die Bewegungen der Wirbelsäule im Yoga als Einladung zur Selbstfürsorge und inneren Transformation betrachten. Mögen wir durch die Praxis dieser Asanas nicht nur die Gesundheit unserer Wirbelsäule verbessern sondern auch eine tiefere Verbindung zu uns selbst und unserer inneren Kraft herstellen. Dem Rücken Liebe zu schenken bedeutet, uns selbst Liebe zu schenken und unsere innere Mitte zu finden. </p>

<p>Wenn du diese Asana-Gruppen in der Praxis erfahren möchtest, dann würde ich mich sehr darüber freuen, dich in meinem „Liebe für den Rücken“ Kurs begrüßen zu dürfen. Den Anmeldelink findest du am Anfang des Beitrags. Solltest du sonst noch Feedback an mich haben oder mir sonst irgendetwas mitteilen wollen, dann zögere nicht, dies <Link className="purpledark" as={Link} to="/contact">
               hier
        </Link> zu tun. </p>

<p>Alles Liebe, Bianca</p> 

                </Card.Text>
            </Card.Body>
            <Card.Footer className="blog-card">17. März 2024</Card.Footer>
            </Card>
        </Row>
      </Container>
    </Container>
  );
}

export default BlogYoga8;
