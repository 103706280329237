import React, { useEffect } from 'react';

function FynderyContainer({ parentUrl }) {

  useEffect(() => {
    const iframeId = 'iframe_14604';
    const containerElement = document.getElementById(`fyndery_container_14604`);
    const iframeUrl = `https://www.fyndery.de/book_course/calendar/14566/1/#courses_tab`;
    
    if (containerElement) {
      containerElement.innerHTML = `
        <iframe id="${iframeId}" src="${iframeUrl}" frameBorder="0" style="width: 100%; min-width: 350px; height: 450px;"></iframe>
        <div style="margin: 0 auto; font-family: JosefinSlab; font-size: 1.5rem; color: white;">
          Probleme bei der Buchung? Tickets können auch über
          <a style="color: #5d2f52" target="_blank" href="https://www.fyndery.de/coach/14566/bianca/#courses_tab">diesen Link</a> gebucht werden.
        </div>
        <div style="margin: 0 auto; font-family: JosefinSlab; font-size: 1rem; color: white;">
        Die Dienstleistungen und Produkte von The Mermaid Yoga sind umsatzsteuerbefreit aufgrund der Kleinunternehmerregelung gem. § 6 Abs. 1 Z 27 UstG. Die Preise verstehen sich inklusive sonstiger Abgaben, Zuschläge oder Versandkosten.
        </div>
      `;
    }
  }, [parentUrl]);

  return (
    <div id={`fyndery_container_14604`}>
      {/* The content will be dynamically added by the useEffect */}
    </div>
  );
}

export default FynderyContainer;
