import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Particle from "../Particle";
import {Link} from "react-router-dom";
import blog1 from "../../Assets/Blog1_Foto1.webp";
import blog2 from "../../Assets/Blog1_Foto2.webp";
import blog3 from "../../Assets/Blog1_Foto3.webp";
import blog4 from "../../Assets/Blog1_Foto4.webp";

function BlogYoga1() {
  return (
    <Container fluid="true" className="blog-section" id="Blog">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "0px",
            }}
          >
          </Col>
          <Col md={12} style={{
            paddingTop: "20px",
              justifyContent: "center" }}>
        </Col>
        </Row>
        
        <Row>
        <Card className="blog-card" style={{ backgroundColor: 'transparent' }}>
            <Card.Body>
                <Card.Text>
                <h1>Vier Jahre Yoga: Eine Reise der Veränderung auf mentaler und körperlicher Ebene</h1>
                <h2>Die transformative Kraft von Yoga</h2>

<p>Vor vier Jahren begann ich eine Reise, die mein Leben auf vielfältige Weise verändert hat. Yoga betrat meine Welt und brachte nicht nur körperliche Bewegung, sondern auch innere Ruhe, mentale Stärke und tiefe Einsichten in die Philosophie des Lebens. In diesem Blogbeitrag möchte ich meine persönliche Reise mit Yoga teilen und wie es mich sowohl auf mentaler als auch auf körperlicher Ebene transformiert hat.</p>

<h2>Wie alles begann</h2>

<p>Meine Yogapraxis begann vor vier Jahren, als ich eine sehr intensive Zeit in meinem damaligen Job erlebte und eine Möglichkeit suchte, meinen Kopf abzuschalten. Damals waren Stress und (oftmals selbst auferlegter) Druck meine täglichen Begleiter, und meine Gedanken schienen niemals zur Ruhe zu kommen. Die ständige Anspannung begann sich auf meine mentale und körperliche Gesundheit auszuwirken. Ich war auf der Suche nach einem Ausweg, nach einem Mittel, um den Strudel der Gedanken zu unterbrechen und meinen Geist zu beruhigen.</p>

<p>Es war in dieser Zeit der Suche und des Verlangens nach Entlastung, dass Yoga in mein Leben trat. Die Stress reduzierende Wirkung der Yogapraxis wird immerhin sehr oft in den unterschiedlichsten Medien angepriesen. Inmitten einer Zeit, die von Hektik, Anspannung und dem ständigen Druck geprägt war, fand ich im Yoga einen Hoffnungsschimmer, eine Möglichkeit, mich von den Strapazen des Alltags zu befreien und meine innere Balance wiederzufinden. Ich sehnte mich nach einem Ort der Ruhe, an dem ich meinem Gedankenkarussell entkommen und meine Sorgen hinter mir lassen konnte.</p>

<img src={blog1} alt="Sitzende Vorwärtsbeuge" style={{ maxWidth: '90%' , maxHeight: '400px' }} />
<br></br>
<br></br>
<p>Mit diesem Gedanken wagte ich Ende 2019 den ersten Schritt auf meiner Yoga Reise. Ich begann mit Yoga-Videos auf Online-Plattformen wie Youtube. Die Unsicherheit darüber, wie ich mich in einem Yogastudio präsentieren würde, hielt mich zunächst davon ab, eine physische Yogaklasse zu besuchen. Diese Selbstzweifel wurzelten in der Vorstellung, dass mein Körperbau nicht dem "typischen" Bild einer Yoga praktizierenden Frau entsprach, wie es oft in den Medien dargestellt wird. Diese Hemmschwelle zu überwinden, war eine Herausforderung für mich.</p>

<p>Ungeachtet dieser Unsicherheiten brachte ich mir selbst bei, auf meiner Yogamatte zu üben. Mithilfe von Youtube-Anleitungen und Online-Kursen tauchte ich tiefer in die Welt des Yoga ein. Die Vielfalt der Übungen und die Möglichkeit, meinen eigenen Rhythmus zu finden, waren befreiend. Ich begann, die positiven Effekte auf meinen Körper und meinen Geist zu spüren, während ich Schritt für Schritt die verschiedenen Asanas erkundete.</p>

<p>Doch gerade als ich begann, mich wohler in meiner eigenen Yogapraxis zu fühlen, brach die Corona-Krise aus. Die Welt wurde von Lockdowns und sozialer Distanzierung heimgesucht, und die Möglichkeit, persönliche Yogastunden zu besuchen, verschwand fast vollständig. In dieser Zeit wurde meine Entscheidung, Yoga zu praktizieren, auf die Probe gestellt. Doch anstatt mich entmutigen zu lassen, nahm ich die Herausforderung an und setzte meine Praxis mit noch größerer Entschlossenheit fort.</p>

<p>Die Online-Welt wurde mein Lehrer und mein Studio. Die globale Krise hatte mich in meiner Reise nicht aufgehalten, sondern vielmehr zu noch mehr Eigenverantwortung und Selbstfürsorge motiviert. Ich erkannte, dass Yoga nicht an physische Räume gebunden ist, sondern eine Praxis ist, die sich überall ausüben lässt, solange man bereit ist, sich darauf einzulassen.</p>

<p>Ich erinnere mich noch gut daran, wie ich in dieser Zeit mit einfachen und kurzen Yoga-Videos auf meiner Yogamatte im Wohnzimmer in den Tag startete, bevor ich mich an meinen Schreibtisch setzte. In diesen Momenten fühlte ich mich nicht nur körperlich gedehnt, sondern auch geistig befreit und bereit für den Tag. Die Anleitung, auf meinen Atem zu achten und die Gedanken einfach vorbeiziehen zu lassen, war wie eine Befreiung von dem ständigen Gedankenkarussell, das mich belastet hatte. Für diese kurze Zeit konnte ich mich wirklich auf den Augenblick einlassen, ohne mich von meinen Sorgen hinreißen zu lassen. Yoga war auch eine Quelle der körperlichen Gesundheit. Die Dehnungen und Stärkungsübungen halfen, Verspannungen zu lösen, die durch den Mangel an Bewegung und die ungewohnte Heimarbeit entstanden waren. Die regelmäßige Praxis trug dazu bei, meinen Körper flexibel zu halten und mein Immunsystem zu stärken, was in Zeiten einer Gesundheitskrise von besonderer Bedeutung war.  Während der herausfordernden Zeiten der Corona-Krise fand ich in Yoga somit einen wertvollen Begleiter, der mir half, mit den Unsicherheiten und Ängsten umzugehen. Als die Welt plötzlich von Unsicherheit geprägt war, bot mir die regelmäßige Praxis von Yoga einen ruhigen Hafen der Stabilität.</p>

<p>Vier Jahre sind nun vergangen, seit ich zum ersten Mal auf meine Yogamatte trat, und die Auswirkungen dieser Entscheidung sind deutlicher denn je. Was damals als einfacher Versuch begann, hat sich zu einer lebensverändernden Praxis entwickelt. Die Fähigkeit, den Moment bewusst wahrzunehmen und Stress auf eine gesunde Weise zu bewältigen, hat sich tief in mein Wesen eingegraben. </p>


<h2>Mentale Veränderungen: Ruhe im Gedankenkarussell</h2>

<p>Mit Yoga habe ich endlich eine Tätigkeit gefunden, die es mir ermöglicht, das Gedankenkarussell abzuschalten. Nach den ersten Monaten der Yogapraxis wusste ich, dass ich etwas Besonderes gefunden hatte. Yoga war nicht nur eine körperliche Übung, sondern auch eine Möglichkeit, meinen Geist zu beruhigen und den Fokus auf das Hier und Jetzt zu lenken. Ich spürte, dass ich auf diesem Weg eine Verbindung zu mir selbst und zu einem tieferen Sinn des Lebens finden konnte. Inmitten der Hektik meines Jobs und der ständigen Ablenkungen des modernen Lebens bot mir Yoga einen Zufluchtsort der Ruhe und des Gleichgewichts.</p>

<img src={blog2} alt="Sitzender Twist" style={{ maxWidth: '90%' , maxHeight: '400px' }}/>
<br></br>
<br></br>
<p>Die achtsame Konzentration auf meinen Atem und die Bewegungen lenken meine Aufmerksamkeit weg von Sorgen und Ängsten des Alltags. Die Yogamatte ist für mich zu einem sicheren Rückzugsort geworden. Hier kann ich meine Gedanken sortieren, mich sammeln und neue Energie schöpfen. Die Praxis hat mich gelehrt, dass Performance-Druck und der Wunsch nach Perfektion auf der Yogamatte keinen Platz haben. Diese Lektion habe ich auf mein tägliches Leben übertragen und bin freier von unrealistischem Streben nach Vollkommenheit.</p>

<p>Stress, der einst mein ständiger Begleiter war, verschwindet nach einer Yogaeinheit wie von Zauberhand. Die Kombination aus körperlichen Asanas und Atemübungen befähigt mich, klarer zu denken und mit einer ruhigeren Perspektive auf Herausforderungen zu reagieren. Die Entspannung, die ich während und nach einer Yogaeinheit erlebe, ist nicht nur flüchtig, sondern begleitet mich durch den Tag und beeinflusst meine Interaktionen positiv.</p>

<p>Die Anwendung der Yogaphilosophie auf mein Leben hat eine tiefgreifende Veränderung in meiner Wahrnehmung, Lebensanschauung und Einstellung bewirkt. Als Schülerin des Yoga bin ich auch Schülerin des Lebens geworden. Eine tiefe Dankbarkeit für das Leben und ein starkes Vertrauen in den Lauf der Dinge haben sich in mir entwickelt. Die Akzeptanz dessen, was ist, und das Loslassen von Kontrolle sind Werte, die ich aus meiner Yogapraxis übernommen habe. Eine besondere Rolle spielte dabei auch meine Yogalehrerausbildung. </p>

<h3>Die Auswirkungen meiner 200h-Yogalehrerausbildung</h3>

<p>Die Anfangsskepsis und Vorurteile, die mich daran hinderten, persönlichen Yogaunterricht zu besuchen, begannen langsam zu verblassen. Während ich meine eigene Praxis vertiefte und Fortschritte machte, erkannte ich, dass Yoga für jede Person zugänglich ist, unabhängig von Körperbau, Geschlecht oder Alter. Die Vielfalt der Menschen in der Online-Yogagemeinschaft inspirierte mich, meine Selbstzweifel zu überwinden und mich selbst zu akzeptieren. Aus diesem Grund entschied ich mich im Jahr 2022 eine 200h Yogalehrerausbildung in Wien zu absolvieren. Wenn ich an diese Zeit zurück denke, erfüllt sich mein Herz mit Freude und Dankbarkeit. Vertrauen in das Universum, Selbstvertrauen und ein unerschütterlicher Glaube an den Sinn hinter jeder Erfahrung sind nun die Leitsterne meines Lebens geworden.
Die Angst, einst ein ständiger Begleiter, ist zur Seite getreten und hat einem überwältigenden Gefühl der Dankbarkeit für die Feinheiten des Lebens Platz gemacht.</p>

<p>Diese Reise hat mir ein neues Selbstvertrauen und eine unerschütterliche Wertschätzung für meinen Körper und seine bemerkenswerten Fähigkeiten geschenkt. Ich habe gelernt, meinen Körper als ein Gefäß der Kraft und der Anmut anzunehmen.</p> 

<p>Eine weitere Lektion, die ich gelernt habe, ist der Glaube daran, dass das Leben sich auf unglaubliche Weise so entfaltet, wie es sollte. Jede Wendung ist ein Kapitel des Wachstums, jede Herausforderung eine Chance zum Aufblühen. Probleme sind nichts anderes als Situationen, die von unseren Erwartungen abweichen, und in ihnen liegt die größte Chance für Wachstum und Selbstentdeckung.</p>

<p>Ich erinnere mich an einen Moment in meiner Ausbildung, der mich besonders tief beeindruckt hat, als jemand unsere Yogalehrerin fragte, ob sie sich jemals Sorgen um die Zukunft mache. Ihre Antwort hat mich zutiefst berührt: "Eigentlich nicht, denn bis jetzt ist immer alles gut ausgegangen." Die Leichtigkeit, mit der sie diese Worte sprach, enthüllte eine tiefe Wahrheit - dass das Leben eine angeborene Weisheit hat, und dass es unsere Aufgabe ist, seinem Fluss zu folgen und zu vertrauen.</p>


<h2>Körperliche Veränderungen: Flexibilität und Selbstakzeptanz</h2>

<p>Die physischen Veränderungen, die ich durch Yoga erlebt habe, sind ebenso bemerkenswert wie die mentalen. Nach den ersten Monaten der Praxis bemerkte ich, dass mir das Gehen leichter fiel. Meine Haltung verbesserte sich, und ich begann, aufrechter auf meinem Schreibtischstuhl zu sitzen. Dies war nur der Anfang einer Reise zu mehr körperlichem Wohlbefinden.</p>

<p>Die Verbesserungen meiner Flexibilität waren erheblich. Die einst unüberwindbar scheinenden Grenzen wurden nach und nach überwunden. Meine Hände erreichen nun mühelos den Boden in einer Vorbeuge, und die Kuhgesicht-Armbewegungen funktionieren auf beiden Seiten. Doch wichtiger als die äußere Flexibilität ist die Fähigkeit, auf meinen Körper zu hören und seine Bedürfnisse zu respektieren. Dort, wo einst Frustration herrschte, da ich in Armbalancen nicht in der Lage war, meine Füße vom Boden zu heben, oder Umkehrhaltungen nicht ausführen konnte, empfinde ich heute eine tiefe Gelassenheit gegenüber den Asanas, die ich noch nicht meistere. </p>

<p>Die Praxis des Yoga hat mich gelehrt, mich nicht mit anderen zu vergleichen, sondern meine eigene Reise anzuerkennen und zu schätzen. Dieser Ansatz der Selbstakzeptanz hat nicht nur meine körperliche Praxis bereichert, sondern beeinflusst auch meine Einstellung zum eigenen Selbstwert.</p>



<h2>Integration in den Alltag und langfristige Auswirkungen der Yoga-Praxis</h2>

<p>Yoga hat sich nicht nur auf meine direkten Erfahrungen während der Praxis ausgewirkt, sondern auch auf die Art und Weise, wie ich meinen Alltag gestalte. Der positive Einfluss auf meine mentale Klarheit und emotionale Stabilität zeigt sich in meiner Fähigkeit, mit Stress umzugehen und klare Entscheidungen zu treffen. Die Gelassenheit, die ich auf der Yogamatte kultiviere, begleitet mich nicht nur während meiner Übungen, sondern hat sich zu einer Grundhaltung entwickelt, die mich durch den Tag führt.</p>

<p>In stressigen Meetings oder bei herausfordernden Aufgaben erinnere ich mich daran, tief durchzuatmen und im Moment zu bleiben. Die Atemtechniken, die ich in der Yogapraxis gelernt habe, dienen als wertvolles Werkzeug, um mich zu zentrieren und meine Gedanken zu fokussieren. Die Gelassenheit, die ich auf der Matte kultiviere, trägt dazu bei, dass ich Situationen aus einer ruhigeren Perspektive betrachten kann. Dadurch bin ich in der Lage, bessere Entscheidungen zu treffen und auf unerwartete Ereignisse flexibler zu reagieren.</p>

<img src={blog3} alt="Sitzender Twist 2"  style={{ maxWidth: '90%' , maxHeight: '400px' }} />
<br></br>
<br></br>
<p>Eine der bemerkenswertesten Auswirkungen meiner langfristigen Yogapraxis ist die Veränderung meiner zwischenmenschlichen Beziehungen. Die Achtsamkeit, die ich während der Übungen kultiviere, hat sich in meinen Interaktionen mit anderen Menschen manifestiert. Ich nehme mir bewusst Zeit, zuzuhören und aufmerksam zu sein, anstatt in Gedanken schon bei der nächsten Aufgabe zu sein. Dies hat dazu geführt, dass meine Verbindungen tiefer und authentischer geworden sind.</p>

<p>Die Veränderungen in meinem Körper haben nicht nur meine physische Gesundheit verbessert, sondern auch meine Einstellung zum Körperbild nachhaltig beeinflusst. Die Vorstellung von einem "perfekten Körper" hat sich gewandelt, und ich habe gelernt, meinen Körper für das zu schätzen, was er ist. Die körperlichen Fortschritte, die ich durch Yoga gemacht habe, erinnern mich daran, wie leistungsfähig und anpassungsfähig mein Körper ist. Anstatt mich mit äußeren Standards zu vergleichen, finde ich Freude daran, meine eigenen Fortschritte zu feiern und mich auf das zu konzentrieren, was mein Körper täglich leistet.</p>

<p>Die Transformation meines Körperbildes hat sich direkt auf mein Selbstvertrauen ausgewirkt. Ich stehe zu meiner Individualität und umarme meine Einzigartigkeit. Diese gestärkte Selbstwahrnehmung hat positive Auswirkungen auf alle Lebensbereiche, sei es bei der Arbeit, in sozialen Situationen oder bei persönlichen Zielen. Ich fühle mich selbstbewusster und ermutigt, neue Herausforderungen anzunehmen und das Leben mit einem offenen Herzen zu erleben.</p>


<h2>Yoga als Praxis, die mein Leben veränderte</h2>

<p>Während ich auf meine vierjährige Reise mit Yoga zurückblicke, bin ich erfüllt von Dankbarkeit für die Veränderungen, die es in meinem Leben bewirkt hat. Die Verbindung von Körper und Geist, die Yoga fördert, hat mir eine neue Dimension der Selbstkenntnis eröffnet. Ich freue mich auf die kommenden Jahre und die Fortsetzung dieser Reise, die niemals endet.</p>

<p>In einer Welt, die oft von Hektik und Stress geprägt ist, ist Yoga für mich zu einem Anker der Ruhe und des Wachstums geworden. Während das Leben weiterhin Höhen und Tiefen bringt, bin ich dankbar, dass ich mit Yoga ein Werkzeug gefunden habe, um mich durch die Wellen des Lebens zu bewegen und dabei meine innere Stärke und Gelassenheit zu bewahren. Die Fähigkeit, auf der Matte zu stehen und den Moment zu erleben, hat sich auf jede Facette meines Lebens ausgewirkt. Ich hoffe, dass meine Erfahrungen Menschen inspirieren, die transformative Kraft von Yoga für sich selbst zu entdecken und eine Reise der Veränderung anzutreten – eine Reise, die Körper, Geist und Seele gleichermaßen berührt.</p>

<p>Auf meinem weiteren Weg der Selbstfindung und Achtsamkeit trage ich die Lehren, die Yoga in meine Seele gewebt hat, mit mir. Jeder Atemzug, jede Pose, jeder Moment der Stille hat zu der Person beigetragen, die ich heute bin. Mit offenen Armen nehme ich die Ungewissheit, die Herausforderungen und die Schönheit des Unbekannten an und weiß, dass das Leben seinen Zauber auf seine Weise entfalten wird.</p>

<img src={blog4} alt="Childs Pose"  style={{ maxWidth: '90%' , maxHeight: '400px' }} />
<br></br>
<br></br>
<p>Wenn du ähnliche Erfahrungen im Zuge deiner Yoga-Reise gemacht hast, würde ich mich sehr freuen, darüber zu hören. Gerne kannst du <Link className="purpledark" as={Link} to="/contact">
               hier
        </Link> mit mir in Kontakt treten. Natürlich sind auch Feedback oder sonstige Anregungen jederzeit erwünscht!</p>

<p>Alles Liebe,
Bianca</p>

                </Card.Text>
            </Card.Body>
            <Card.Footer className="blog-card">6. September 2023</Card.Footer>
            </Card>
        </Row>
      </Container>
    </Container>
  );
}

export default BlogYoga1;
