import React, { useState} from "react";
import { Container, Row } from "react-bootstrap";
import "../../style.css";

const ContactForm = () => {
    const [status, setStatus] = useState(null)
    const [email, setEmail] = useState("")
    const [vorname, setVorname] = useState("")
    const [text, setText] = useState("")
    const [betreff, setBetreff] = useState("")
    const MAIL_TOKEN = process.env.REACT_APP_MAIL_TOKEN

  
    const handleSubmit = async event => {
      event.preventDefault()

      try{
        const subject = "KONTAKTANFRAGE " + betreff.toString();
        const body = "Angabe Email: " + email.toString() + "<br>" + "Von: " + vorname.toString() + "<br>" +  "Text: " + text.toString();
        window.Email.send({
          SecureToken: MAIL_TOKEN,
          To : "kontakt@themermaid.yoga",
          From : "office@themermaid.yoga",
          Subject : subject,
          Body : body
        }).then(
          resp => {
              if (resp === "OK") {
                setStatus("SUCCESS")
              }
              else {
                setStatus("ERROR")
              }
          }
        );
  
        setEmail("")
        setVorname("")
        setText("")
        setBetreff("")
        
        // const json = response
        const json = "a"
        if (json.status === 200) {
          setStatus("SUCCESS")
        }
      } catch (err) {
          if (setStatus === "SUCCESS"){
            return
          }
        setStatus("ERROR")
      }
    }
  
    const handleEmailChange = event => {
      const { value } = event.target
      setEmail(value)
    }
  
    const handleNameChange = event => {
      const { value } = event.target
      setVorname(value)
    }

    const handleTextChange = event => {
        const { value } = event.target
        setText(value)
      }

      const handleBetreffChange = event => {
        const { value } = event.target
        setBetreff(value)
      }

    return (
        <Container id="contactform">
            <Container>
              <Row>
            <div className="Contact">
              <section>
                {status === "ERROR" && (
                  <>
                    <h1>Oops, ein Problem ist aufgetreten..</h1>
                    <p>
                      Überprüfe deine Eingaben und probiere es erneut
                    </p>
                  </>
                )}
                {status === "SUCCESS" && (
                  <>
                  <br></br>
                    <p style={{ fontSize: "1.5em" }}><b>Vielen Dank für deine Kontaktanfrage!</b></p>
                  </>
                )}
                <div>
                <form onSubmit={handleSubmit}>
                  <input 
                    type="text"
                    placeholder="Vorname"
                    value={vorname}
                    onChange={handleNameChange}
                    required
                  />
                  <br></br>
                  <input 
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                  />
                  <br/>
                  <input 
                    type="betreff"
                    placeholder="Betreff"
                    value={betreff}
                    onChange={handleBetreffChange}
                    required
                  />
                  <br/>
                  <textarea rows={4} cols={40}
                    type="text"
                    placeholder="Text"
                    value={text}
                    onChange={handleTextChange}
                    required
                  />
                  <br />
                  <button fluid="true" className="button"
                  >
                    Abschicken
                  </button>
                  </form>
                </div>
              </section>
            </div>
            </Row>
            </Container>
          </Container>
    )

}

export default ContactForm