import React from 'react';
import FynderyContainer from './FynderyContainer'; // Import your FynderyContainer component

function ParentComponent() {
  // Obtain the URL you need from wherever you have access to it
  const parentUrl = window.location.href; // Change this to your actual source of the URL

  return (
    <div>
      {/* Pass the URL as a prop to FynderyContainer */}
      <FynderyContainer parentUrl={parentUrl} />
    </div>
  );
}

export default ParentComponent;
