import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import laptopImg from "../../Assets/Bianca_face2.webp";
import { Slide } from "react-awesome-reveal";


function About() {
  return (
    <Container fluid="true" className="about-section">
      <Particle />
      <Container>
      <Row>
      <Slide triggerOnce duration={2000}> 
      <Col md={13} className="about-blur">
      “The best cure for the body is a quiet mind.” <br/> Napoleon Bonaparte
      </Col>
      </Slide>
      </Row>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "0px",
            }}
          >
            <Slide triggerOnce duration={2000}>
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Über mich
            </h1>
            </Slide>
            <blockquote className="blockquote mb-0">
            <p style={{ textAlign: "justify" }}>
            Hallo, ich bin Bianca, Yogalehrerin aus Leidenschaft und Fan von Meerjungfrauen, Einhörnern und Alpakas.
            <br />
            <br />
            Ich habe Japanologie und Wirtschaftsinformatik an der Universität Wien studiert und war fünf Jahre lang als Unternehmensberaterin und IT-Managerin tätig. Während dieser Zeit habe ich festgestellt, dass ich dringend einen Ausgleich zu meinem stressigen Arbeitsalltag brauchte, um meine Gedanken zur Ruhe kommen zu lassen. Das führte mich zum Yoga, welches ich nun mittlerweile seit über vier Jahren täglich praktiziere.
            <br />
            <br />
            <p>Ich stellte dabei so viele positive Auswirkungen auf meinen Körper und Geist fest (besserer Schlaf, mehr Ausgeglichenheit, höhere Resilienz…), dass ich mich Anfang 2022 dazu entschloss, die 200h-Yogalehrerausbildung zu absolvieren. Ich habe die Ausbildung im selben Jahr bei Nancy Krüger in Wien abgeschlossen. Diese Zeit war für mich so inspirierend und prägend, dass in mir der Wunsch gewachsen ist, Yoga und all die positiven Erfahrungen, die ich durch Yoga gemacht habe und immer noch mache, an andere Menschen weiterzugeben und Yoga zu unterrichten. Seit September 2023 bin ich neben meinem neuen Vollzeitjob (ebenfalls wieder im IT Bereich) nebenberuflich als Yogalehrerin tätig. Aus dem Yoga-Unterrichten ziehe ich gleichzeitig Kraft und Entspannung. Diese Tätigkeit ist zu einer großen Quelle der Weiterentwicklung und Erdung für mich geworden. </p>
          </p>
          </blockquote>
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "80px", paddingBottom: "0px" }}
            className="about-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>
          <Col md={12} style={{
            paddingTop: "20px",
              justifyContent: "center" }}>
              <blockquote className="blockquote mb-0">
             <p style={{ textAlign: "justify" }}>
             Neben Yoga ist meine zweite große Leidenschaft Japan. Daher integriere ich gerne Inhalte der japanischen Philosophie und Kultur in meine Yogastunden. Mir liegt viel daran, den Teilnehmer:innen meiner Kurse nicht nur körperliche Übungen zu vermitteln, sondern auch einen Raum für inneres Wachstum und Selbstreflexion zu schaffen. Durch meine Yoga-Stunden möchte ich die Menschen dazu inspirieren, sich auf allen Ebenen zu entfalten und ihr volles Potenzial zu entdecken.
            <br />
            Meine Lieblingszeit, um Yoga zu praktizieren, ist morgens nach dem Aufstehen. Dies hilft mir, den Tag mit positiver Energie zu beginnen und mich auf die bevorstehenden Herausforderungen vorzubereiten.           <br />
            <br />
            Zu meinen Lieblingsasanas gehören die Meerjungfrau und der Super Soldier.
            <br />
            Ich freue mich darauf, mein Wissen und meine Leidenschaft für Yoga mit anderen Menschen zu teilen und ihnen dabei zu helfen, ihre körperliche und geistige Gesundheit zu verbessern. Ich freue mich darauf, dich in meinen Yoga-Stunden willkommen zu heißen und mit dir gemeinsam eine bereichernde und transformative Reise anzutreten!
            </p>
            </blockquote>
          </Col>

          <Col md={12} style={{
            paddingTop: "40px",
              justifyContent: "center" }}>
                <Slide triggerOnce duration={2000}>
<h1 style={{ textAlign: "center", fontSize: "2.1em", paddingBottom: "20px"}}>Warum „The Mermaid Yoga“?</h1>
</Slide>
              <blockquote className="blockquote mb-0">
             <p style={{ textAlign: "justify" }}>

<p>Vielleicht hast du dich gefragt, warum ich mich für den Namen „The Mermaid Yoga“ entschieden habe, beziehungsweise welche Bedeutung dahinter steckt.</p>

<p>Wie oben bereits erwähnt, gehört die Meerjungfrau zu meinen absoluten Lieblingsasanas. Sie steht für mich persönlich für Durchhaltevermögen, Disziplin, und Selbstbewusstsein, da es bei mir einige Zeit gedauert hat, bis ich diese Asana beherrscht habe. </p>

<p>Die Meerjungfrau verkörpert außerdem so viele wunderbare Qualitäten, die für mich auch im Yoga eine Rolle spielen: Weiblichkeit, Transformation, Unabhängigkeit, Liebe und Magie. Die verspielte Natur der Meerjungfrau erinnert mich daran, das Leben mit einem Lächeln und einem Hauch von Magie zu genießen. </p>

<p>Genau wie die Meerjungfrau eine Verbindung zwischen Land und Meer darstellt, strebe ich im Yoga nach einer ganzheitlichen Balance zwischen Körper und Geist, zwischen Anspannung und Entspannung. </p>

<p>Ich möchte mit diesem Namen zeigen, dass es bei The Mermaid Yoga nicht nur um körperliche Übungen geht, sondern auch um die Entwicklung von Selbstbewusstsein, Selbstliebe und innerer Stärke. Ich glaube an das Magische im Leben und daran, dass jeder und jede von uns die Hauptrolle in seiner oder ihrer eigenen Geschichte spielt. </p>

             </p>
             </blockquote>
             </Col>
        </Row>
        <Row>
        <Col md={12} className="home-about-welcome"></Col>
        <Slide triggerOnce duration={2000}>

        <h1 style={{  fontSize: "2.1em", paddingBottom: "20px"}}>Qualifikation & Ausbildungen</h1>
        </Slide>
        <li>200h Hatha Vinyasa Teacher Training (Nancy Krüger)</li>
        <li>Yoga Anatomie Ausbildung (Dr. Peter Pöck)</li>
        <li>Art of teaching beginners (Jason Crandell)</li>
        <li>300h Yoga Teacher Training (Jason Crandell)</li>
        </Row>
      </Container>
    </Container>
  );
}

export default About;
