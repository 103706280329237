import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Home2 from "./Home2";
import Newsletter from "../Newsletter/Newsletter";
import { Slide } from "react-awesome-reveal";

function Home() {
  return (
    <section>
    <Particle />
      <Container fluid="true" className="home-section" id="home">
        <Container className="home-content">
          <Row>
          <Col className="home-header">
          <Slide triggerOnce direction="left" duration={2000}>
              <h1 className="home-h1">
              Online Plus Size Yoga & Online Hatha Yoga
              </h1>
              </Slide>
            </Col>
            <div class="d-none d-lg-block d-xl-none">
            <Col className="home-header"></Col></div>
            <Col  className="home-header">
            <div style={{ fontSize: 25 }}>
            <Slide triggerOnce direction="right" duration={2000}>
            Yoga is about embracing our bodies as they are, and finding strength, flexibility and self-love on the mat, regardless of size.
            </Slide>
            </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
      <Newsletter id="News" />
    </section>
  );
}

export default Home;
