import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import { useLocation } from "react-router-dom";


function Signup() {
  const location = useLocation();
  const data = location.state

  return (
    <section>
    <Particle />
      <Container fluid="true" className="about-section" id="Signup">
        <Container className="about-content">
          <Row>
            <Col>
              <h1 style={{ paddingBottom: 15, paddingTop: 30, justifyContent: "center" }}>
              Danke für deine Anmeldung{data.vorname ? ` ${data.vorname}` : ""}{""}!
              </h1>
              <p>Du wirst jeden Moment eine Email bekommen, in der du deine Email Adresse bestätigen musst.</p>
              <br></br>
              <br></br>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Signup;
