import React from "react";
import { Container, Row, Col, Card, Button, CardGroup } from "react-bootstrap";
import Particle from "../Particle";
import { useNavigate } from 'react-router-dom';

// Image imports
import blog1 from "../../Assets/Blog1_Foto1.webp";
import blog2 from "../../Assets/Blog3_Foto3.webp";
import blog3 from "../../Assets/Blog4_Foto6.webp";
import blog4 from "../../Assets/Blog5_Foto5.webp";
import blog7 from "../../Assets/Blog7_Foto1.webp";
import blog8 from "../../Assets/Blog8_Foto3.webp";
import blog10 from "../../Assets/Blog10_Foto1.webp";
import blog11 from "../../Assets/Blog11_Foto3.webp";
import blog12 from "../../Assets/Blog12_Foto5.webp";
import blog13 from "../../Assets/Blog13_Foto5.webp";
import blog14 from "../../Assets/Blog14_Foto1.webp";
import blog15 from "../../Assets/Blog15_Foto5.webp";
import blog16 from "../../Assets/Blog16_Foto5.webp";
import blog17 from "../../Assets/Blog17_Foto2.webp";


// Reusable BlogCard Component
function BlogCard({ image, title, date, route }) {
  const navigate = useNavigate();
  return (
    <Col md={4} className="d-flex align-items-stretch mb-4">
      <Card className="blog-card" style={{ backgroundColor: 'transparent' }}>
        <Card.Img variant="top" src={image} />
        <Card.Body>
          <Card.Title><h1 className="blog-card-heading">{title}</h1></Card.Title>
        </Card.Body>
        <Button className="blog-button" onClick={() => navigate(route)}>Zum Blogbeitrag</Button>
        <Card.Footer>{date}</Card.Footer>
      </Card>
    </Col>
  );
}

function BlogYoga() {
  // Data array for blog posts
  const blogPosts = [
    { image: blog1, title: "Vier Jahre Yoga: Eine Reise der Veränderung", date: "6. September 2023", route: "/VierJahreYoga" },
    { image: blog2, title: "Performance-Druck in der heutigen Yoga-Welt", date: "23. September 2023", route: "/PerformanceImYoga" },
    { image: blog3, title: "Yoga gegen Rückenschmerzen", date: "17. Oktober 2023", route: "/YogaGegenRueckenschmerzen" },
    { image: blog4, title: "Yoga gegen Periodenschmerzen", date: "3. Jänner 2024", route: "/YogaGegenPeriodenschmerzen" },
    { image: blog7, title: "Plus Size Yoga: Warum Körperideale keinen Platz haben", date: "3. März 2024", route: "/PlusSizeYogaBlog" },
    { image: blog8, title: "Dem Rücken Liebe schenken: Bewegungsrichtungen der Wirbelsäule", date: "17. März 2024", route: "/LiebeFuerDenRuecken" },
    { image: blog10, title: "7 Gründe nicht mit Yoga zu beginnen", date: "31. Mai 2024", route: "/GruendeNichtMitYogaZuBeginnen" },
    { image: blog11, title: "Das Wurzelchakra – Ich vertraue dem Leben", date: "28. Juli 2024", route: "/DasWurzelchakra" },
    { image: blog12, title: "Das Sakralchakra – Ich akzeptiere meine Emotionen", date: "16. August 2024", route: "/DasSakralchakra" },
    { image: blog13, title: "Das Nabelchakra – Ich bin mutig", date: "30. August 2024", route: "/DasNabelchakra" },
    { image: blog14, title: "Das Herzchakra – Mein Herz ist erfüllt von Dankbarkeit", date: "12. September 2024", route: "/DasHerzchakra" },
    { image: blog15, title: "Das Kehlchakra – Ich spreche meine Wahrheit", date: "28. September 2024", route: "/DasKehlchakra" },
    { image: blog16, title: "Das Stirnchakra – Ich vertraue meiner Intuition", date: "15. Oktober 2024", route: "/DasStirnchakra" },
    { image: blog17, title: "Das Kronenchakra – Ich bin mit allem verbunden", date: "2. November 2024", route: "/DasKronenchakra" }
  ];

  return (
    <Container fluid className="blog-section" id="Blog">
      <Particle />
      <Container>
        <CardGroup>
          <Row style={{ justifyContent: "center", padding: "10px" }}>
            {blogPosts.slice().reverse().map((post, index) => (
              <BlogCard key={index} {...post} />
            ))}
          </Row>
        </CardGroup>
      </Container>
    </Container>
  );
}

export default BlogYoga;
