import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Safe Space",
          "Kreativität & Abwechslung",
          "Inklusion",
          "Ganzheitliche Entwicklung",
          "Wohlfühlen"
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
    
      }}
    />
  );
}

export default Type;
