import React from "react";
import { Container, Row } from "react-bootstrap";
import myImg from "../../Assets/Pose8_Full.webp";
import ParentComponent from "./ParentComponent";
import {Link} from "react-router-dom";

function CourseContent() {
    return (
<Container className="kurs-about-section">
<Row md={13} style={{ justifyContent: "center",  color: "white", padding: "20px" }}>
    <h2>Buche jetzt deinen Kurs</h2>
    <ParentComponent />
    </Row>

    <Row md={13} style={{ justifyContent: "center", color: "white", padding: "20px"}}>


    <h2 style={{paddingTop: '20px' }}>1. Plus Size Yoga & Curvy Yoga für Frauen - alle Level</h2>

    <p>Meine <b>Online Plus Size und Curvy Yogastunden</b> am Donnerstag beinhalten zwar teilweise kraftvolle Elemente, sind aber überwiegend ruhige Einheiten, welche einen sicheren und einladenden Ort schaffen sollen, an dem wir uns als <b>Plus Size und Curvy Frauen wohl und akzeptiert fühlen</b>. Ich bin der Meinung, dass diesem Personenkreis in der heutigen Yoga-Welt noch viel zu wenig Beachtung geschenkt wird. Ich glaube fest daran, dass Yoga für <b>jeden Körper und jedes Fitnesslevel</b> zugänglich sein sollte. Jeder verdient die heilenden und stärkenden Vorteile des Yoga, unabhängig von äußeren Erwartungen oder Standards. Ich möchte dich ermutigen, dich selbst zu feiern, deine eigenen Grenzen zu erkunden und deine Stärken zu entdecken. Denn Yoga sollte nicht dazu dienen, uns zu verändern, sondern uns dabei helfen, uns selbst besser zu verstehen und zu schätzen.  Du benötigst für diese Yogaeinheiten eine Yogamatte, bequeme Kleidung, zwei Yogablöcke und einen Yogagurt. <b>Mehr Informationen findest du in der Anmeldemaske unter “Beschreibung und weitere Infos”</b>.  </p>


    <h2 style={{paddingTop: '20px' }}>2. Hatha Yoga – alle Level</h2>

    <p>Ich biete jede Woche freitags einen Hatha Yoga Flow für alle Level online via Teams an. Meine Hatha Yogaeinheiten sind <b>kreativ und abwechslungsreich</b>. Dich erwarten <b>dynamische und kraftvolle Stunden</b>, aber auch <b>Stunden der Entspannung und des Loslassens</b>. Du benötigst eine bequeme Yogamatte. Ebenso würde ich die Verwendung zweier Yogablöcke und eines Yogagurtes empfehlen. <b>Mehr Informationen findest du in der Anmeldemaske unter “Beschreibung und weitere Infos”</b>.</p>

    <h2 style={{paddingTop: '20px' }}>3. Yoga-Anfängerkurs – Die Grundlagen der Yogapraxis</h2>

    <p>
    Entdecke die wunderbare Welt des Yoga in meinem <b>Yoga-Anfängerkurs bestehend aus sechs Yoga-Einheiten</b>! Meine 6-wöchigen Yoga-Anfängerkurse finden mehrmals im Jahr online statt. Die Ankündigung des nächsten Kurses findet immer via Instagram oder meinem Newsletter statt. Sie sind der perfekte Einstieg für alle, die die <b>Grundlagen dieser jahrtausendealten, heilenden Praxis</b> kennen lernen möchten. Du benötigst natürlich deine Yogamatte. Um das Beste aus jeder Übung herauszuholen, benötigst du außerdem zwei Yogablöcke und einen Yogagurt. <b>Mehr Informationen findest du in der Anmeldemaske unter “Beschreibung und weitere Infos”</b>.</p>

    <h2 style={{paddingTop: '20px' }}>4. Spezialstunden</h2>

    <p>In regelmäßigen Abständen bitte ich <b>Spezialstunden</b> zu unterschiedlichen Fokusthemen an wie beispielsweise <b>„Die Bewegungsrichtungen der Wirbelsäule“</b>oder <b>„Liebe für den Bauch“</b>. Diese Einheiten finden meistens am Wochenende statt und werden von mir auf Instagram und in meinem <Link className="purpledark" as={Link} to="/newsletter">
                                Newsletter
                          </Link> angekündigt. 
    <b> Mehr Informationen zur jeweiligen Spezialstunde findest du in der Anmeldemaske unter “Beschreibung und weitere Infos”</b>.
    </p>

    <img src={myImg} alt="Bianca" style={{ maxWidth: '90%', maxHeight: '500px', paddingBottom: '20px', paddingTop: '20px', objectFit: 'contain' }}/>
    <br></br>
    <br></br>
    <h2>Warum du meinen Yogaunterricht besuchen solltest</h2>
    Hier sind 5 Gründe, warum du unbedingt dabei sein solltest:
    <br/>
    <br/>
    <ul><li><b>Ganzheitliches Yogaerlebnis:</b> Meine Kurse bieten ein ganzheitliches Yogaerlebnis, bei dem Körper,
    Geist und Seele in Einklang gebracht werden. Du wirst nicht nur körperlich fitter und flexibler, sondern
    auch innerlich gestärkt und entspannt.</li>
    <br/>
    <li><b>Für jeden geeignet:</b> Egal, ob du ein Anfänger oder bereits fortgeschritten bist, meine Yogakurse sind
    für alle Erfahrungsstufen offen. Jeder kann von den positiven Auswirkungen des Yoga profitieren und
    sich weiterentwickeln.</li>
    <br/>
    <li><b>Individuelle Betreuung:</b> Mir ist es wichtig, dass du dich in meinen Kursen wohl und gut aufgehoben
    fühlst. Daher lege ich großen Wert auf individuelle Betreuung und gehe auf die Bedürfnisse jedes
    einzelnen Teilnehmers ein.</li>
    <br/>
    <li><b>Stressabbau und Entspannung:</b> In unserer hektischen Welt ist es essentiell, Zeit für Entspannung und
    Stressabbau zu finden. Yoga hilft dir dabei, innere Ruhe zu finden und den Alltagsstress hinter dir zu
    lassen.</li>
    <br/>
    <li><b>Gemeinschaft und Austausch:</b> In meinen Yogakursen entsteht eine warme und offene Gemeinschaft,
    in der du dich mit Gleichgesinnten austauschen und neue Freundschaften schließen kannst</li></ul>
    <br/>
    Überzeuge dich selbst von den vielfältigen Vorteilen meiner Yogakurse und melde dich jetzt an. Lass uns

    gemeinsam dein Wohlbefinden auf ein neues Level heben. <b>Ich freue mich darauf, dich in meinen Kursen
    zu begrüßen!</b>
    </Row>

        
</Container>
    )
}
export default CourseContent;