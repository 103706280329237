import React from "react";
import { Container } from "react-bootstrap";
import Particle from "../Particle";

function Widerrufsbelehrung() {
  return (
    <section>
    <Particle />
      <Container fluid="true" className="ds-about-description">
        <Container className="imp-about-section">

        <h1>Widerrufsbelehrung</h1>
        <h2>Widerrufsrecht</h2>
            <p>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu
            widerrufen.
            Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses.
            Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (The Mermaid Yoga e.U., Wilhelm
            Lebsaft-Gasse 2a/2 3400 Klosterneuburg,+43 677 612 099 8200, <a href="mailto:kontakt@themermaid.yoga">kontakt@themermaid.yoga</a>)
            mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über
            Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte
            Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
            Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des
            Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</p>

        <h2>Folgen des Widerrufs</h2>
        <p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen
            erhalten haben, unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag
            zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns
            eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei
            der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich
            etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte
            berechnet.
            Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen sollen, so
            haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem
            Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrags
            unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im
            Vertrag vorgesehenen Dienstleistungen entspricht.</p>

        <h2>Ausschluss bzw. vorzeitiges Erlöschen des Widerrufsrechts</h2>
        <p>Das Widerrufsrecht besteht nicht bei Verträgen zur Erbringung von Dienstleistungen in den
            Bereichen Beherbergung zu anderen Zwecken als zu Wohnzwecken, Beförderung von
            Waren, Kraftfahrzeugvermietung, Lieferung von Speisen und Getränken sowie zur
            Erbringung weiterer Dienstleistungen im Zusammenhang mit Freizeitbetätigungen, wenn der
            Vertrag für die Erbringung einen spezifischen Termin oder Zeitraum vorsieht.
            Das Widerrufsrecht gilt nicht für Verbraucher, die zum Zeitpunkt des Vertragsschlusses
            keinem Mitgliedstaat der Europäischen Union angehören und deren alleiniger Wohnsitz und
            Lieferadresse zum Zeitpunkt des Vertragsschlusses außerhalb der Europäischen Union
            liegen.
            Das Widerrufsrecht erlischt vorzeitig bei Verträgen über Dienstleistungen, wenn wir die
            Dienstleistung vollständig erbracht haben und wir mit der Ausführung der Dienstleistung erst

            begonnen haben, nachdem Sie dazu Ihre ausdrückliche Zustimmung gegeben haben und
            gleichzeitig Ihre Kenntnis davon bestätigt haben, dass Sie Ihr Widerrufsrecht bei
            vollständiger Vertragserfüllung durch uns verlieren.</p>

        <h2>Muster-Widerrufsformular</h2>
        <p>Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und
            senden Sie es zurück.</p>
            <ul class="dashed">
            <li>An The Mermaid Yoga e.U., Wilhelm Lebsaft-Gasse 2a/2, 3400 Klosterneuburg, Österreich,
            E-Mail: kontakt@themermaid.yoga </li>
            <li>Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den
            Kauf der folgenden Waren (*)/die Erbringung der folgenden Dienstleistung (*)</li>
            <li>Bestellt am (*) / erhalten am(*)</li>
            <li>Name des/der Verbraucher(s)</li>
            <li>Anschrift des/der Verbraucher(s)</li>
            <li>Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)</li>
            <li>Datum</li></ul>
            (*) Unzutreffendes streichen.

        </Container>
      </Container>
    </section>
  );
}

export default Widerrufsbelehrung;
