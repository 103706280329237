import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function AutoPlayMobile() {

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    cssEase: "linear",
    arrows: false,
  };
    
    
    return (
      <div className="slider-container">
        <Slider {...settings}>
        <div class="blog-card-main">
            <figure>
              <figcaption>
                <div class="bsb-ratings text-light mb-3" data-bsb-star="5" data-bsb-star-off="0"></div>
                <blockquote class="bsb-blockquote-icon mb-4">Da ich dieses Mal schon ein wenig geübter war, ist mir erst jetzt richtig aufgefallen, wie Bianca detailliert die Asanas anleitet und, wenn nötig, Alternativen vorzeigt, dazwischen immer wieder konzentriert über Zoom die Kursteilnehmer beobachtet und gegebenenfalls korrigiert ... man fühlt sich total sicher, und auch als Neuling bestens aufgehoben! </blockquote>
                <h6 class="mb-2">Andrea H.</h6>
              </figcaption>
            </figure>
          </div>
          <div class="blog-card-main">
            <figure>
              <figcaption>
                <div class="bsb-ratings text-light mb-3" data-bsb-star="5" data-bsb-star-off="0"></div>
                <blockquote class="bsb-blockquote-icon mb-4">Mit einer ruhigen, sympathischen Stimme führt Bianca durch die Stunde, alle Übungen wurden super erklärt. Ich kam durchaus ins Schwitzen, aber es hat unheimlich viel Spaß gemacht und das war sicher nicht meine letzte Stunde mit Bianca.</blockquote>
                <h6 class="mb-2">Ramona S.</h6>
              </figcaption>
            </figure>
        </div>
        <div class="blog-card-main">
            <figure>
              <figcaption>
                <div class="bsb-ratings text-light mb-3" data-bsb-star="5" data-bsb-star-off="0"></div>
                <blockquote class="bsb-blockquote-icon mb-4">Die Stunde war super abgestimmt auf die Teilnehmer. Besonders die Varianten fand ich toll. Bis auf eine Matte und etwas Platz haben wir nichts gebraucht. Schnell, easy, nicht zu teuer und eine super Möglichkeit, um in stressigen Zeiten etwas für den eigenen Körper und Geist zu tun :)</blockquote>
                <h6 class="mb-2">Anna S.</h6>
              </figcaption>
            </figure>
        </div>
        <div class="blog-card-main">
            <figure>
              <figcaption>
                <div class="bsb-ratings text-light mb-3" data-bsb-star="5" data-bsb-star-off="0"></div>
                <blockquote class="bsb-blockquote-icon mb-4">Tolle Stunde! Ohne Druck und somit stressbefreit durch die effiziente Yoga Einheit. Danke, wir sehen uns wieder! :-) </blockquote>
                <h6 class="mb-2">Andrea P.</h6>
              </figcaption>
            </figure>
        </div>
        <div class="blog-card-main">
            <figure>
              <figcaption>
                <div class="bsb-ratings text-light mb-3" data-bsb-star="5" data-bsb-star-off="0"></div>
                <blockquote class="bsb-blockquote-icon mb-4">Der Anfängerkurs ist top gestaltet: anfangs bekommt man einen Überblick, was einen in der Stunde erwartet und welche Hilfsmittel man evtl. benötigt. Das Tempo ist machbar zügig, aber Bianca nimmt sich auch Zeit für`s Durchschnaufen. Am Ende wird man mit einer Körperreise oder einer kleinen Meditation sanft und sehr entspannend belohnt.</blockquote>
                <h6 class="mb-2">Andrea H.</h6>
              </figcaption>
            </figure>
        </div>
        <div class="blog-card-main">
            <figure>
              <figcaption>
                <div class="bsb-ratings text-light mb-3" data-bsb-star="5" data-bsb-star-off="0"></div>
                <blockquote class="bsb-blockquote-icon mb-4">Vielen Dank für diese wunderbare (Privat-)Einheit ;-P Ich finde es toll, dass du immer Inputs zum angesagten Thema gibst. Deine Anleitung zu den Asanas und Flows ist klar und deutlich, und du erinnerst auch ans Weiteratmen ;-) DANKE! Ich bin gerne wieder dabei, wenn es die Zeit zulässt {"<"}3</blockquote>
                <h6 class="mb-2">Karin S.</h6>
              </figcaption>
            </figure>
        </div>
        <div class="blog-card-main">
            <figure>
              <figcaption>
                <div class="bsb-ratings text-light mb-3" data-bsb-star="5" data-bsb-star-off="0"></div>
                <blockquote class="bsb-blockquote-icon mb-4">Hatte heute die 1. Online Yogastunde mit Bianca. Ich habe Yoga bisher noch nie so entspannend jedoch auch mobilisierend erlebt. Eine perfekte Mischung. Auch ohne ständig auf den Monitor aufschauen zu müssen, wenn man die Positionen noch nicht kennt, perfekt erklärt. Jederzeit wieder gerne.</blockquote>
                <h6 class="mb-2">Sabrina K.</h6>
              </figcaption>
            </figure>
        </div>
        </Slider>
      </div>
      );
    }
export default AutoPlayMobile;