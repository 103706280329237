import React from "react";
import { Container, Row, Col, Card, Button, CardGroup } from "react-bootstrap";
import Particle from "../Particle";
import blog2 from "../../Assets/Blog2_FOTO5.webp";
import blog6 from "../../Assets/Blog6_Foto1.webp";
import blog9 from "../../Assets/Blog9_Foto2.webp";
import { useNavigate } from 'react-router-dom';

function BlogMindfulness() {
  const navigate = useNavigate();

    const routeChange = () =>{ 
      navigate("/AchtsamkeitImAlltag");
    }

    const routeChange2 = () =>{ 
      navigate("/Resilienz");
    }

    const routeChange3 = () =>{ 
      navigate("/ErkenntnisseUeberMichUndDasLeben1");
    }

  return (
      <Container fluid="true" className="blog-section" id="Blog"> 
      <Particle />
        <Container>
        <CardGroup>
          <Row style={{ justifyContent: "center", padding: "10px" }}>

          <Col md={4}
              style={{
                justifyContent: "center",
                paddingTop: "30px",
                paddingBottom: "0px",
              }}>
          
          <Card className="blog-card" style={{ backgroundColor: 'transparent'}}>
            <Card.Img variant="top" src={blog9} />
            <Card.Body>
              <Card.Title><h1 className="blog-card-heading">Erkenntnisse über mich und das Leben – Teil 1</h1></Card.Title>
              <Card.Text>
              </Card.Text>
            </Card.Body>
              <Button className="blog-button" onClick={routeChange3}>Zum Blogbeitrag</Button>
              <Card.Footer>27. April 2024</Card.Footer>
          </Card>
          </Col>

            <Col md={4}
              style={{
                justifyContent: "center",
                paddingTop: "30px",
                paddingBottom: "0px",
              }}>
          
          <Card className="blog-card" style={{ backgroundColor: 'transparent'}}>
            <Card.Img variant="top" src={blog6} />
            <Card.Body>
              <Card.Title><h1 className="blog-card-heading">Resilienz: Wie man über den Dingen steht</h1></Card.Title>
              <Card.Text>
              </Card.Text>
            </Card.Body>
              <Button className="blog-button" onClick={routeChange2}>Zum Blogbeitrag</Button>
              <Card.Footer>24. Februar 2024</Card.Footer>
          </Card>
          </Col>

          <Col md={4}
              style={{
                justifyContent: "center",
                paddingTop: "30px",
                paddingBottom: "0px",
              }}>
          
          <Card className="blog-card" style={{ backgroundColor: 'transparent'}}>
            <Card.Img variant="top" src={blog2} />
            <Card.Body>
              <Card.Title><h1 className="blog-card-heading">Achtsamkeit im Alltag: Tipps für Anfänger</h1></Card.Title>
              <Card.Text>
              </Card.Text>
            </Card.Body>
              <Button className="blog-button" onClick={routeChange}>Zum Blogbeitrag</Button>
              <Card.Footer>11. September 2023</Card.Footer>
          </Card>
          </Col>

          </Row>
          </CardGroup>
        </Container>
      </Container>       
  );
}

export default BlogMindfulness;
