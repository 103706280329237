import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Particle from "../Particle";
import {Link} from "react-router-dom";
import blog1 from "../../Assets/Blog3_Foto1.webp";
import blog2 from "../../Assets/Blog3_Foto2.webp";
import blog3 from "../../Assets/Blog3_Foto3.webp";
import blog4 from "../../Assets/Blog3_Foto4.webp";
import blog5 from "../../Assets/Blog3_Foto5.webp";
import blog6 from "../../Assets/Blog3_Foto6.webp";

function BlogYoga3() {
  return (
    <Container fluid="true" className="blog-section" id="Blog">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "0px",
            }}
          >
          </Col>
          <Col md={12} style={{
            paddingTop: "20px",
              justifyContent: "center" }}>
        </Col>
        </Row>
        
        <Row>
        <Card className="blog-card" style={{ backgroundColor: 'transparent' }}>
            <Card.Body>
                <Card.Text>
                <h1>Performance-Druck in der heutigen Yoga-Welt: Eine tiefe Reflexion</h1>
                  <p>In der heutigen Zeit erleben viele Menschen einen stetig wachsenden Druck in Bezug auf ihre Performance und Leistung. Dieser Druck manifestiert sich in verschiedenen Lebensbereichen, sei es im Beruf, in der Schule, im Sport oder sogar im sozialen Umfeld. Die modernen Gesellschaften sind geprägt von einem Wettbewerbsdenken, das oft dazu führt, dass Menschen sich selbst enormen Erwartungen aussetzen. Die permanente Sichtbarkeit und Vergleichbarkeit in den sozialen Medien verstärken diesen Druck zusätzlich. In dieser Welt, die ständig in Bewegung ist und in der wir permanent nach Leistung und Perfektion streben, hat sich auch die Yoga-Welt verändert. Yoga, das einst als spirituelle Praxis der Selbstfindung und des inneren Friedens bekannt war, ist heute oft von Performance-Druck und Wettbewerb geprägt. In diesem Blogbeitrag werden wir uns mit diesem Phänomen auseinandersetzen und darüber nachdenken, wie wir wieder zur Essenz des Yoga zurückkehren können.</p>

                  <h2>Die Ursprünge des Yoga – der Blick nach Innen</h2>
                  <p>Die Ursprünge des Yoga reichen mehrere tausend Jahre zurück und sind eng mit der hinduistischen Philosophie und Spiritualität verbunden. Die ersten schriftlichen Aufzeichnungen über Yoga finden sich in den alten indischen Schriften, den Veden, die zwischen 1500 und 500 v. Chr. entstanden sind. Diese Schriften enthalten bereits Erwähnungen von Techniken zur Meditation und zur Erweiterung des Bewusstseins.</p>
                  <p>Es war jedoch Patanjali, der im 2. Jahrhundert v. Chr. die Yoga Sutras verfasste, die als grundlegende Texte für die Yogapraxis und Philosophie dienen. In den Yoga Sutras definierte Patanjali Yoga als "das Zur-Ruhe-Bringen der Gedanken im Geist." Diese Definition betont die zentrale Rolle der inneren Ruhe und des mentalen Friedens in der yogischen Praxis.</p>
                  <p>Der ursprüngliche Zweck des Yoga bestand darin, das individuelle Bewusstsein zu erweitern und eine tiefere Verbindung zur inneren Welt herzustellen. Die verschiedenen Pfade des Yoga, wie Hatha-Yoga (körperliche Praxis), Bhakti-Yoga (Hingabe), Jnana-Yoga (Wissen) und Karma-Yoga (Handeln), wurden entwickelt, um unterschiedlichen Menschen auf ihrem spirituellen Weg gerecht zu werden.</p>
                  <p>Die Essenz des Yoga lag in der Suche nach Erleuchtung und der Befreiung von den Fesseln des Egos. Es war eine Reise der Selbstentdeckung und Selbsttransformation, bei der äußere Leistung oder körperliche Perfektion nicht im Mittelpunkt standen.</p>

                  <img src={blog1} alt="Skandasana" style={{ maxWidth: '90%' , maxHeight: '500px', paddingBottom: '20px', paddingTop: '20px' }} />
                  <h2>Yoga unter dem Einfluss der sozialen Medien – der Drang nach äußerer Perfektion</h2>
                  <p>Leider hat sich dieses ursprüngliche Verständnis des Yoga in der heutigen schnelllebigen Welt verändert. Yoga wird oft auf seine physische Komponente reduziert, wobei die tiefe spirituelle Dimension vernachlässigt wird. Dieser Wandel hat den Weg für den Performance-Druck geebnet, dem viele heutige Yogis und Yoginis ausgesetzt sind.</p>
                  <p>Die Auswirkungen von Social Media auf den Performance-Druck in der Yoga-Welt sind nicht zu unterschätzen. Plattformen wie Instagram und TikTok sind zu Schauplätzen für atemberaubende Yoga-Posen und beeindruckende akrobatische Übungen geworden. Yogis und Yoginis, die in scheinbar unmöglichen Posen posieren oder ihre scheinbar endlosen Fortschritte präsentieren, haben Tausende von Followern und sind zu Influencern geworden, die von vielen bewundert werden. Während dies die Yoga-Community in gewisser Weise bereichert, da die Verbreitung der Yogapraxis gefördert wird, wird ein subtiler, aber bedeutender Druck erzeugt.</p>
                  <p>Die endlosen Ströme von perfekt inszenierten Fotos und Videos können dazu führen, dass Menschen sich mit einem idealisierten Bild von Yoga vergleichen. Das Bild, das oft vermittelt wird, ist das einer dünnen und sehr flexiblen Person, die mühelos in komplizierten Posen verweilt. Dies führt zu einem verzerrten Wahrnehmungsbild darüber, wie Yoga praktiziert werden sollte. Der Drang, ähnliche Posen zu meistern und ähnlich ästhetische Bilder zu teilen, kann zu einem ungesunden Wettbewerb und einem Gefühl der Unzulänglichkeit führen.</p>
                  <p>Die Betonung von äußerer Perfektion und die Darstellung von Yoga als eine Art sportlicher Leistung kann die ursprüngliche spirituelle Tiefe der Praxis leicht überdecken. Es ist wichtig zu erkennen, dass die Menschen, die diese beeindruckenden Bilder teilen, oft Jahre der Übung und Hingabe hinter sich haben. Aber auf Social Media wird dieser Prozess oft übersehen, was dazu führt, dass viele glauben, dass sie in kurzer Zeit die gleichen Ergebnisse erzielen sollten.</p>
                  <p>Es ist daher entscheidend, sich bewusst zu machen, dass die Realität hinter den Darstellungen auf Social Media eine ganz andere ist. Die wahre Essenz des Yoga sollte nicht in der äußeren Darstellung und Präsentation, sondern in der inneren Transformation und dem inneren Frieden liegen. Wenn wir uns dieser Tatsache bewusst sind, können wir uns darauf konzentrieren, wie Yoga uns auf unserer persönlichen Reise unterstützen kann, ohne uns dabei mit anderen zu vergleichen.</p>

                  <img src={blog2} alt="Tree Pose" style={{ maxWidth: '90%' , maxHeight: '500px', paddingBottom: '20px', paddingTop: '20px' }} />
                  <h2>Die Konsequenzen des Performance-Drucks</h2>
                  <p>Der allgegenwärtige Performance-Druck bringt ernsthafte Konsequenzen, sowohl für die individuellen Praktizierenden als auch für die Yoga-Gemeinschaft im Allgemeinen, mit sich.</p>
                  <p><li><b>Verletzungsgefahr: </b>Wenn Menschen den Fokus auf äußere Leistung legen und versuchen, komplizierte Asanas zu meistern, ohne ausreichende Vorbereitung und Achtsamkeit, erhöhen sie das Risiko von Verletzungen. Beim Nachmachen komplexer Positionen, können leicht die eigenen physischen Grenzen überschritten werden. Die korrekte Ausführung von Asanas entscheidend. Ohne angemessene Anleitung und Technik können falsche Bewegungen oder Überdehnungen zu Muskelzerrungen, Bänderrissen oder anderen Verletzungen führen. Eine ausreichende Aufwärmphase und das Beachten des eigenen Körpers sind unerlässlich, um Verletzungen zu vermeiden.</li></p>
                  <p><li><b>Vergleichsdenken: </b>Soziale Medien haben eine Kultur des Vergleichs gefördert. Menschen sehen täglich Bilder und Videos von anderen Yogis und Yoginis, die atemberaubende Asanas perfekt ausführen oder an exotischen Orten meditieren, und gelangen in einen Teufelskreis des Selbstzweifels und der Unzufriedenheit. Das Vergleichsdenken führt dazu, dass Menschen sich selbst in ihrer Yogapraxis oder in ihrem Körper negativ bewerten.</li></p>
                  <p><li><b>Verlust der spirituellen Tiefe: </b>Eine der ursprünglichen Intentionen des Yoga war es, eine tiefere spirituelle Verbindung und Selbsterkenntnis zu erlangen. Diese Dimension des Yoga kann leicht übersehen werden, wenn die Praxis ausschließlich auf äußere Perfektion und sichtbaren Fortschritt reduziert wird. Wenn Menschen sich zu sehr darauf konzentrieren, beeindruckende Asanas zu beherrschen oder die Anzahl der Follower auf ihren Social-Media-Konten zu steigern, können sie die wahre Essenz des Yoga übersehen. Die spirituelle Komponente, die Achtsamkeit, Meditation und Selbstreflexion einschließt, gerät in den Hintergrund.</li></p>
                  <p><li><b>Druck und Stress: </b>Der Performance-Druck, in Bezug auf äußere Leistung oder den Vergleich mit anderen, kann zu erhöhtem Stress und Anspannung führen. Dies steht im klaren Widerspruch zur eigentlichen Zielsetzung des Yoga, die darin besteht, innere Ruhe, Ausgeglichenheit und Entspannung zu finden. Wenn Menschen das Gefühl haben, dass sie im Yoga "besser" oder "fortgeschrittener" sein müssen, um Anerkennung zu erlangen oder sich selbst zu beweisen, dann verlieren sie oft den Sinn der Praxis, der darin besteht, sich von Stress und Leistungsdruck zu befreien.</li></p>

                  <img src={blog3} alt="Skandasana Variation" style={{ maxWidth: '90%' , maxHeight: '500px', paddingBottom: '20px', paddingTop: '20px' }} />
                  
                  <h2>Wie wir unseren Fokus wieder auf das Wesentliche lenken können</h2>
                  <p>Es gibt Schritte, die Praktizierende und die Yoga-Gemeinschaft als Ganzes unternehmen können, um dem Performance-Druck im Yoga entgegenzuwirken und um wieder zur ursprünglichen Essenz des Yoga zurückzufinden.</p>
                  <p><li><b>Bewusstsein und Selbstreflexion: </b>Der erste Schritt besteht darin, uns unser eigenes Verhalten bewusst zu machen. Lassen wir uns von äußeren Erwartungen und Druck beeinflussen? Warum praktizieren wir Yoga und welchen Zweck erfüllt es für uns? Dieses Bewusstsein über unsere eigene Motivation kann uns dabei helfen, nicht zielführende Gedankenmuster zu erkennen und diesen entgegenzuwirken.</li></p>
                  <p><li><b>Achtsamkeitspraxis: </b>Wir integrieren Achtsamkeit in unsere Yogapraxis. Statt uns ausschließlich auf äußere Perfektion und sichtbare Fortschritte zu konzentrieren, lenken wir unsere Aufmerksamkeit auf den gegenwärtigen Moment und unser inneres Erleben. Wir fokussieren uns auf unsere Atmung, unsere Empfindungen und Emotionen während der Praxis. Achtsamkeit hilft uns, den Vergleich mit anderen loszulassen und die Freude am Yoga im Hier und Jetzt zu finden.</li></p>
                  <p><li><b>Bildung und Verständnis: </b>Wir vertiefen unser Verständnis für die Geschichte und die Philosophie des Yoga. Die Wurzeln des Yoga liegen nicht nur in körperlichen Übungen, sondern auch in spirituellen und philosophischen Lehren. Wenn wir die tiefere Bedeutung hinter den Praktiken verstehen, erkennen wir, dass Yoga weit mehr ist als nur äußere Leistung. Dieses Verständnis kann dazu beitragen, unseren Fokus auf das Innere zu lenken und den Druck zu mindern.</li></p>
                  <p><li><b>Gemeinschaft: </b>Wir suchen nach einer unterstützenden Yoga-Gemeinschaft. In solchen Gemeinschaften geht es nicht darum, sich mit anderen zu messen, sondern sich gegenseitig in der persönlichen Entwicklung zu unterstützen. Eine positive Gemeinschaft kann eine sichere Umgebung bieten, in der wir unser Yoga ohne Urteile oder Vergleiche praktizieren können. Der Austausch von Erfahrungen und die Unterstützung durch Gleichgesinnte können motivierend sein und den Druck mindern.</li></p>
                  <p><li><b>Eigenliebe und Akzeptanz: </b>Wir erinnern uns daran, dass Yoga dazu dient, uns selbst zu lieben und zu akzeptieren, genau so, wie wir sind. Unser Weg in der Yogapraxis ist einzigartig und persönlich. Wir müssen uns nicht mit anderen vergleichen oder deren Fortschritte erreichen. Wir üben Selbstliebe und Akzeptanz für unseren eigenen Körper und Geist. Dies hilft uns dabei, den negativen Einfluss des Performance-Drucks zu reduzieren und das wahre Ziel des Yoga zu erreichen: innere Harmonie und Wohlbefinden.</li></p>

                  <img src={blog4} alt="Pigeon" style={{ maxWidth: '90%' , maxHeight: '500px', paddingBottom: '20px', paddingTop: '20px' }} />
                  
                  <h2>Mein Weg von der Unsicherheit und Angst zur Selbstakzeptanz</h2>
                  <p>Yoga hat mein Leben auf vielfältige Art und Weise bereichert und verändert. Doch auf meinem Weg zur Erkenntniss der wahren Essenz des Yoga, ist der Performance-Druck auch an mir nicht spurlos vorübergegangen.</p>

                  <h3>Ein untypischer Yoga-Körper in Social Media Welt</h3>
                  <p>Zu Beginn meiner Yoga-Reise fiel mir auf, dass mein Körper nicht dem entsprach, was ich auf Social Media oft sah. Die scheinbar endlosen Bilder von jungen, schlanken und überaus flexiblen Yogis und Yoginis haben mich damals zu Beginn meiner Yoga-Reise durchaus verunsichert. Ich stellte mir die Frage, ob ich überhaupt Yoga praktizieren könnte, geschweige denn einen echten Nutzen daraus ziehen könne, da ich ein paar Kilos mehr auf den Rippen habe und meine Gelenke nicht so beweglich schienen wie die der Personen auf den Bildern.</p>
                  <p>Die überwältigende Präsenz dieser "Yoga-Ideale" in den sozialen Medien ließ mich anfangs an mir selbst zweifeln. Doch die Sehnsucht nach innerem Frieden und einer besseren Verbindung zu meinem Körper trieb mich an, meine Zweifel zu überwinden und Yoga auszuprobieren.</p>

                  <img src={blog5} alt="Low Lunge" style={{ maxWidth: '90%' , maxHeight: '500px', paddingBottom: '20px', paddingTop: '20px' }} />

                  <p>Am Anfang traute ich mich nicht, ein physisches Yogastudio zu besuchen. Die Angst davor, über mein Erscheinungsbild beurteilt oder gar verspottet zu werden, ließ mich zögern. Die Vorstellung, dass andere in meinem Umfeld über mich tuscheln könnten, wenn ich mich auf meine Matte stellte, war beängstigend.</p>
                  <p>Stattdessen begann ich, Yoga zu Hause zu praktizieren. In meiner vertrauten Umgebung konnte ich mich ungestört auf die Übungen konzentrieren, ohne mich von Blicken oder vermeintlich kritischen Gedanken anderer ablenken zu lassen. Die ersten Monate meiner Yogapraxis verliefen somit in meiner eigenen kleinen Welt, und ich fand langsam Vertrauen in meine Fähigkeiten und meinen Körper.</p>
                  <p>Mit der Zeit wurde ich mutiger und ruhiger. Ich bemerkte, wie Yoga nicht nur meinen Körper, sondern auch meinen Geist veränderte. Die Selbstzweifel und Unsicherheiten, die mich zu Beginn begleitet hatten, begannen sich aufzulösen. Ich realisierte, dass Yoga weit mehr ist als die äußere Darstellung auf Social Media. Es ist eine persönliche Reise zur Selbstliebe, Selbstakzeptanz und innerem Frieden.</p>
                  <p>Jedoch tauchten die Zweifel und Ängste nochmals auf - nämlich als ich vor der Entscheidung stand, die Ausbildung zur Yogalehrerin zu machen. Gedanken wie „Kann so jemand wie ich überhaupt ein Yogalehrer sein?", „Was ist, wenn ich die schlechteste und unbeweglichste Person in der Ausbildung bin?" und „Was denken die anderen über mich?" waren zu dieser Zeit allgegenwärtig. Und dennoch gab es diese klitzekleine andere Stimme, welche mir sagte, dass ich gut genug sei.</p>

                  <h3>Die Yogalehrerausbildung als Wendepunkt</h3>

                  <p>Ich entschloss mich, auf diese kleine Stimme zu hören und die negativen Gedanken zur Seite zu schieben. Ich trat meine Yogalehrerausbildung an. Die Ausbildung wurde zu einer tiefgreifenden Erfahrung, die mich nicht nur körperlich forderte, sondern auch meine spirituelle Verbindung zum Yoga vertiefte. Erstaunlicherweise konnte ich ohne Probleme mit meinen Mitstudierenden mithalten, und ich wurde mir meiner eigenen Fähigkeiten und meiner wachsenden Liebe zum Yoga bewusst.</p>
                  <p>Die Ausbildung öffnete mir die Augen für die Essenz des Yoga. Ich begriff nun gänzlich, dass es nicht darum geht, die perfekte Pose zu meistern oder den athletischsten Körper zu haben. Es geht darum, mit sich selbst Frieden zu schließen, Achtsamkeit zu entwickeln und ein tieferes Verständnis für den eigenen Körper und Geist zu gewinnen.</p>
                  <p>Die Yoga-Philosophie lehrte mich, dass es keine Norm gibt, an die ich mich anpassen muss. Jeder Körper ist anders, und jeder Mensch hat seine eigenen Grenzen und Potenziale. Im Yoga geht es darum, sich selbst zu akzeptieren und sich auf die persönliche Entwicklung zu konzentrieren, unabhängig von äußeren Erwartungen oder Standards.</p>
                  <p>Mein persönlicher Yoga-Weg hat mich gelehrt, dass die wahre Schönheit des Yoga in der Vielfalt liegt. Jeder kann von dieser uralten Praxis profitieren, unabhängig von Körpertyp oder Fitnesslevel. Die Yoga-Community sollte ein Ort der Unterstützung, des Verständnisses und der Akzeptanz sein. Denn letztendlich ist Yoga eine Reise zu sich selbst – eine Reise, die uns lehrt, uns selbst zu lieben und inneren Frieden zu finden, unabhängig von den äußeren Einflüssen und Erwartungen.</p>

                  <img src={blog6} alt="Forward Fold" style={{ maxWidth: '90%' , maxHeight: '500px', paddingBottom: '20px', paddingTop: '20px' }} />

                  <h3>Mein zukünftiger Fokus: Plus Size und Curvy Yoga</h3>
                  <p>Durch meine persönlichen Erfahrungen und die Überwindung des Performance-Drucks habe ich einen festen Entschluss gefasst: Ich möchte mich als Yogalehrerin vor allem auf Menschen konzentrieren, die nicht dem „Idealbild" einer Yoga praktizierenden Person entsprechen, wie sie so oft in den sozialen Medien präsentiert wird. Mein Herz schlägt für Curvy und Plus Size Frauen, die, den Wunsch verspüren, etwas Gutes für ihren Körper und Geist zu tun.</p>
                  <p>Meine Vision ist es, einen sicheren und einladenden Ort zu schaffen, an dem sich diese Frauen wohl und akzeptiert fühlen. Ein Ort, an dem sie ohne Druck und Angst ihrer Yogapraxis nachgehen können. Ich glaube fest daran, dass Yoga für jeden Körper und jedes Fitnesslevel zugänglich sein sollte. Jeder verdient die heilenden und stärkenden Vorteile des Yoga, unabhängig von äußeren Erwartungen oder Standards. Ich möchte diese Frauen ermutigen, sich selbst zu feiern, ihre eigenen Grenzen zu erkunden und ihre Stärken zu entdecken. Denn Yoga sollte nicht dazu dienen, uns zu verändern, sondern uns dabei helfen, uns selbst besser zu verstehen und zu schätzen.</p>
                  <p>In meiner Rolle als Yogalehrerin habe ich mit Curvy und Plus Size Yoga meine Nische gefunden und es erfüllt mich mit Stolz und Freude, daran zu denken, wie vielen Menschen ich in Zukunft durch meinen Unterricht helfen kann, authentisch zu sein und sich selbst treu zu bleiben. Ich freue mich darauf, die Schönheit und Kraft in jeder einzelnen Person zu erkennen und gemeinsam eine Reise zur Selbstakzeptanz und inneren Ruhe zu unternehmen. Yoga sollte nicht dazu dienen, sich zu vergleichen, sondern sich selbst zu feiern und zu lieben – das ist meine Botschaft und mein Engagement als Yogalehrerin.</p>
                  <p>Ich würde mich sehr darüber freuen, über deine Erfahrungen mit dem Performance-Druck im Yoga zu hören! Hast du dich aufgrund von Social Media auch schon einmal unsicher und demotiviert bezüglich deiner Yogapraxis gefühlt? Gerne kannst du <Link className="purpledark" as={Link} to="/contact">
               hier
        </Link> mit mir in Kontakt treten. Natürlich sind auch Feedback oder sonstige Anregungen jederzeit erwünscht!</p>
                  <p>Alles Liebe, Bianca</p>



                </Card.Text>
            </Card.Body>
            <Card.Footer className="blog-card">23. September 2023</Card.Footer>
            </Card>
        </Row>
      </Container>
    </Container>
  );
}

export default BlogYoga3;
