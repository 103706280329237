import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import ContactForm from "./ContactForm";


function Contact() {

  return (
    <section>
    <Particle />
      <Container fluid="true" className="contact-section" id="Contact">
        <Container className="contact-section-content">
          <Row md={13} style={{ justifyContent: "center", padding: "10px" }}>
          <Col style={{
                  justifyContent: "center",
                  paddingTop: "30px",
                  paddingBottom: "0px",
                }}>
            <h1 style={{ fontSize: "3.1em", paddingBottom: "10px" }}>
              Kontaktiere mich!</h1>
          </Col>
          </Row>
          <p style={{ fontSize: "1.3em"}}>
          Falls du Fragen oder Anliegen zu meinem Angebot hast, mir Feedback geben oder Wünsche äußern möchtest, melde dich bei mir. Ich freue mich sehr von dir zu lesen und bemühe mich um eine schnelle Antwort!
          </p>
          <ContactForm />
        </Container>
      </Container>
    </section>
  );
}

export default Contact;
