import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import CourseContent from "./courseContent";
import ParentComponent from "./ParentComponent";

function Course() {
  return (
    <section>
    <Container fluid="true" className="kurs-section" id="kurs">
      <Particle />
      <Container className="about-section">
      <Row md={13} style={{ justifyContent: "center", padding: "10px" }}>
      <Col style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "0px",
            }}>
        <h1 style={{ fontSize: "3.1em", paddingBottom: "10px" }}>
          Meine Online Yogakurse</h1>
      </Col>
      </Row>
      </Container>
    </Container>
    <CourseContent />
    <ParentComponent />
  </section>
  );
}

export default Course;
