import React from "react";
import {Link} from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiFillInstagram,
} from "react-icons/ai";
function Footer() {
  return (
    <Container fluid="true" className="footer">
      <Row>
      <Col md="2" className="footer-body">
        <Link as={Link} to="/agb">
               <h3>AGB</h3>
        </Link>
        </Col>
        <Col md="2" className="footer-body">
        <Link as={Link} to="/Widerrufsbelehrung">
               <h3>Widerrufsbelehrung</h3>
        </Link>
        </Col>
        <Col md="2" className="footer-body">
        <Link as={Link} to="/Impressum">
               <h3>Impressum</h3>
        </Link>
        </Col>
        <Col md="2" className="footer-body">
        <Link as={Link} to="/datenschutz">
               <h3>Datenschutzerklärung</h3>
        </Link>
        </Col>
        <Col md="3" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://www.instagram.com/themermaid.yoga"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiFillInstagram /> Instagram
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
