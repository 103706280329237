import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";

function Datenschutz() {
  return (
    <section>
    <Particle />
    <Container fluid="true" className="ds-about-description">
        <Container className="imp-about-section">
          <Row>
            <Col>
            <div>
            <h1>Datenschutzerklärung</h1>
            <br/>
<h2>1. Datenschutz auf einen Blick</h2>
<h3>Allgemeine Hinweise</h3>
<p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen
Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen
Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.</p>
<h3>Datenerfassung auf dieser Website</h3>
<h4>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h4>
<p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten
können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung
entnehmen.</p>
<h4>Wie erfassen wir Ihre Daten?</h4>
<p>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.
B. um Daten handeln, die Sie in ein Kontaktformular eingeben.</p>
<p>Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch
unsere ITSysteme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem
oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website
betreten.</p>

<h4>Wofür nutzen wir Ihre Daten?</h4>
<p>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten.
Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.</p>
<h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
<p>Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung
oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt
haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das
Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
</p>
<p>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.
</p>

<h2>2. Hosting</h2>
<p>Diese Website wird extern gehostet. Die personenbezogenen Daten, die auf dieser Website erfasst

werden, werden auf den Servern des Hosters / der Hoster gespeichert. Hierbei kann es sich v. a. um IP-
Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen,

Websitezugriffe und sonstige Daten, die über eine Website generiert werden, handeln.</p>
<p>Wir hosten die Inhalte unserer Website bei folgendem Anbieter: Hetzner.</p>

<p>Anbieter ist:<br/>Hetzner Online GmbH<br/> Industriestr. 25<br/> 91710 Gunzenhausen<br/>
(nachfolgend Hetzner)</p>
<p>Details entnehmen Sie der Datenschutzerklärung von Hetzner:
https://www.hetzner.com/de/rechtliches/datenschutz. Die Verwendung von Hetzner erfolgt auf Grundlage
von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer möglichst zuverlässigen
Darstellung unserer Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die
Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 165 Abs 3 TKG,
soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät
des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Unser(e) Hoster wird bzw.
werden Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten erforderlich
ist und unsere Weisungen in Bezug auf diese Daten befolgen. Die Einwilligung ist jederzeit widerrufbar.
</p>
<h2>3. Allgemeine Hinweise und Pflichtinformationen</h2>
<h3>Datenschutz</h3>
<p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln
Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften
sowie dieser Datenschutzerklärung.</p>
<p>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die
vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
erläutert auch, wie und zu welchem Zweck das geschieht.</p>

<p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-
Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte

ist nicht möglich.</p>
<h3>Hinweis zur verantwortlichen Stelle</h3>
<p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
<p>Bianca Bleicher, BSc MA<br/>
Wilhelm Lebsaft-Gasse 2a/2<br/>
3400 Klosterneuburg<br/>
Österreich</p>

<p>E-Mail: office@mermaid.yoga</p>
<p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit

anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-
Mail-Adressen o. Ä.) entscheidet.</p>

<h2>Speicherdauer</h2>
<p>Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde,
verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt.
Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung
widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die
Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche
Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.</p>
<h3>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</h3>

<p>Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen
Daten auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere
Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen
Einwilligung in die Übertragung personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung
außerdem auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies
oder in den Zugriff auf Informationen in Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt
haben, erfolgt die Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung
ist jederzeit widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur Durchführung vorvertraglicher
Maßnahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des
Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen Verpflichtung
erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf
Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. Über die jeweils im
Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden Absätzen dieser Datenschutzerklärung
informiert.</p>
<h3>Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten</h3>
<p>Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen
datenschutzrechtlich nicht sicheren Drittstaaten. Wenn diese Tools aktiv sind, können Ihre
personenbezogene Daten in diese Drittstaaten übertragen und dort verarbeitet werden. Wir weisen darauf
hin, dass in diesen Ländern kein mit der EU vergleichbares Datenschutzniveau garantiert werden kann.
Beispielsweise sind US-Unternehmen dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden
herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher
nicht ausgeschlossen werden, dass US-Behörden (z. B. Geheimdienste) Ihre auf US-Servern befindlichen
Daten zu Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese
Verarbeitungstätigkeiten keinen Einfluss.</p>
<h3>Empfänger von personenbezogenen Daten</h3>
<p>Im Rahmen unserer Geschäftstätigkeit arbeiten wir mit verschiedenen externen Stellen zusammen.
Dabei ist teilweise auch eine Übermittlung von personenbezogenen Daten an diese externen Stellen
erforderlich. Wir geben personenbezogene Daten nur dann an externe Stellen weiter, wenn dies im
Rahmen einer Vertragserfüllung erforderlich ist, wenn wir gesetzlich hierzu verpflichtet sind (z. B.
Weitergabe von Daten an Steuerbehörden), wenn wir ein berechtigtes Interesse nach Art. 6 Abs. 1 lit. f
DSGVO an der Weitergabe 5 / 11 haben oder wenn eine sonstige Rechtsgrundlage die Datenweitergabe
erlaubt. Beim Einsatz von Auftragsverarbeitern geben wir personenbezogene Daten unserer Kunden nur
auf Grundlage eines gültigen Vertrags über Auftragsverarbeitung weiter. Im Falle einer gemeinsamen
Verarbeitung wird ein Vertrag über gemeinsame Verarbeitung geschlossen.</p>
<h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
<p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie
können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>
<h3>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art.
21 DSGVO)</h3>
<p>WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F
DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS
IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN
AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR
IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI

DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE
VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO). WERDEN IHRE
PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO
HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER
DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE
PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER
DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).</p>
<h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
<p>Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres
Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet
anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</p>
<h3>Recht auf Datenübertragbarkeit</h3>
<p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines
Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren
Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
<h3>Auskunft, Berichtigung und Löschung</h3>
<p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf
unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und
Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung
dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich
jederzeit an uns wenden.</p>
<h3>Recht auf Einschränkung der Verarbeitung</h3>
<p>Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der
Verarbeitung besteht in folgenden Fällen:</p>
<p><li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten,
benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht,
die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
<li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie
statt der Löschung die Einschränkung der Datenverarbeitung verlangen. </li>
<li>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der
Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. </li>
<li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung
zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen
Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
personenbezogenen Daten zu verlangen.</li></p>
<p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten –
von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder

Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union
oder eines Mitgliedstaats verarbeitet werden.</p>
<h3>SSL- bzw. TLS-Verschlüsselung</h3>
<p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie
zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
TLSVerschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des
Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>
<p>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln,
nicht von Dritten mitgelesen werden.</p>
<h3>Widerspruch gegen Werbe-E-Mails</h3>
<p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung
von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen.
Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten
Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.</p>
<h2>4. Datenerfassung auf dieser Website</h2>
<h3>Server-Log-Dateien</h3>

<p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-
LogDateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>

<li>Browsertyp und Browserversion</li>
<li>verwendetes Betriebssystem</li>
<li>Referrer URL</li>
<li>Hostname des zugreifenden Rechners</li>
<li>Uhrzeit der Serveranfrage</li>
<li>IP-Adresse</li>
<br/>
<p>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</p>
<p>Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
Websitebetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der
Optimierung seiner Website – hierzu müssen die Server-Log-Files erfasst werden.</p>
<h3>Kontaktformular</h3>
<p>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der
Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre
Einwilligung weiter.</p>
<p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre
Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten
Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder
auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist
jederzeit widerrufbar.</p>
<p>Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung
auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung

entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen –
insbesondere Aufbewahrungsfristen – bleiben unberührt.</p>
<h2>Anfrage per E-Mail, Telefon oder Telefax</h2>
<p>Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus
hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres
Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung
weiter.</p>
<p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre
Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten
Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder
auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist
jederzeit widerrufbar.</p>
<p>Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur
Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die
Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.</p>
<h2>5. Newsletter</h2>
<h3>Newsletterdaten</h3>
<p>Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen
eine EMail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass Sie der Inhaber der
angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind. Weitere
Daten werden nicht bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir ausschließlich
für den Versand der angeforderten Informationen und geben diese nicht an Dritte weiter.</p>
<p>Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt ausschließlich
auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung
der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters können Sie jederzeit
widerrufen, etwa über den „Austragen“-Link im Newsletter. Die Rechtmäßigkeit der bereits erfolgten
Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.</p>
<p>Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu
Ihrer Austragung aus dem Newsletter bei uns bzw. dem Newsletterdiensteanbieter gespeichert und nach
der Abbestellung des Newsletters oder nach Zweckfortfall aus der Newsletterverteilerliste gelöscht. Wir
behalten uns vor, E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem Ermessen im Rahmen
unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO zu löschen oder zu sperren.</p>
<p>Daten, die zu anderen Zwecken bei uns gespeichert wurden, bleiben hiervon unberührt.</p>
<p>Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns bzw. dem
Newsletterdiensteanbieter ggf. in einer Blacklist gespeichert, sofern dies zur Verhinderung künftiger
Mailings erforderlich ist. Die Daten aus der Blacklist werden nur für diesen Zweck verwendet und nicht
mit anderen Daten zusammengeführt. Dies dient sowohl Ihrem Interesse als auch unserem Interesse an
der Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes Interesse im Sinne
des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht befristet. Sie können
der Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes Interesse überwiegen.</p>

<h3>MailerLite</h3>
<p>Diese Website nutzt MailerLite für den Versand von Newslettern. Anbieter ist die UAB “MailerLite”, J.
Basanaviciaus 15, LT-03108 Vilnius, Litauen (nachfolgend MailerLite).
MailerLite ist ein Dienst, mit dem u.a. der Versand von Newslettern organisiert und analysiert werden kann. Die
von Ihnen zum Zwecke des Newsletterbezugs eingegeben Daten werden auf den Servern von MailerLite
gespeichert.</p>
<p>Wenn Sie keine Analyse durch MailerLite wollen, müssen Sie den Newsletter abbestellen. Hierfür stellen wir in
jeder Newsletternachricht einen entsprechenden Link zur Verfügung.</p>
<h4>Datenanalyse durch MailerLite</h4>
<p>Mit Hilfe von MailerLite ist es uns möglich, unsere Newsletter-Kampagnen zu analysieren. So können wir z.B.
sehen, ob eine Newsletter-Nachricht geöffnet wurde und welche Links ggf. angeklickt wurden. Auf diese Weise
können wir u.a. feststellen, welche Links besonders oft angeklickt wurden.
Außerdem können wir erkennen, ob nach dem Öffnen/ Anklicken bestimmte vorher definierte Aktionen
durchgeführt wurden (Conversion-Rate). Wir können so z.B. erkennen, ob Sie nach dem Anklicken des Newsletters
einen Kauf getätigt haben.</p>
<p>MailerLite ermöglicht es uns auch, die Newsletter-Empfänger anhand verschiedener Kategorien zu unterteilen
(„clustern”). Dabei lassen sich die Newsletterempfänger z. B. nach Alter, Geschlecht oder Wohnort unterteilen. Auf
diese Weise lassen sich die Newsletter besser an die jeweiligen Zielgruppen anpassen.</p>
<p>Ausführliche Informationen zu den Funktionen von MailerLite entnehmen Sie folgendem
Link: https://www.mailerlite.com/features.</p>
<p>Die Datenschutzerklärung von MailerLite finden Sie unter: https://www.mailerlite.com/legal/privacy-policy.
</p>

<h2>6. Plugins und Tools</h2>
<h3>Google Fonts (lokales Hosting)</h3>

<p>Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Google Fonts, die von
Google bereitgestellt werden. Die Google Fonts sind lokal installiert. Eine Verbindung zu Servern von
Google findet dabei nicht statt.</p>
<p>Weitere Informationen zu Google Fonts finden Sie unter https://developers.google.com/fonts/faq und
in der Datenschutzerklärung von Google: https://policies.google.com/privacy?hl=de.</p>

<h3>Fyndery</h3>
<p>Die Anmeldung zu den auf dieser Website angebotenen Yogakursen und der Kauf von auf dieser
Website angebotenen digitalen Produkten erfolgt über die Software Fyndery. Für die Anmeldung zu den
Kursen sind folgende Informationen notwendig: Vorname, Nachnahme, Straße & Hausnummer,
Postleitzahl, Stadt, Land und Telefonnummer. Für die Anmeldung muss kein Account auf Fyndery
angelegt werden.</p>
<p>Die AGB von Fyndery sind unter folgendem Link zu finden:
https://www.fyndery.de/agb_participants/</p>
<p>Die Datenschutzerklärung von Fyndery ist unter folgendem Link zu finden:
https://www.fyndery.de/privacy_notice/</p>
<p>Der Auftragsverarbeitungsvertrag von Fyndery ist unter folgendem Link zu finden:
https://www.fyndery.de/data_processing/</p>

<h3>Microsoft Teams</h3>
<p>Für das Abhalten von Online Live Yogastunden wird der Microsoft Dienst Teams herangezogen.</p>
<p>Bei der Teilnahme an Online Live Yogastunden via Teams, werden Teile Ihrer persönlichen Daten verarbeitet.
Der einzige Zweck der Verarbeitung ist es, Ihnen zu ermöglichen, an den Online Yogastunden teilnehmen zu
können. Die rechtliche Grundlage für die Verarbeitung Ihrer Daten lässt sich im DSGVO Artikel 6(1)(b) finden, da
die Verarbeitung notwendig ist, um den Dienstleistungsvertrag Ihnen gegenüber zu erfüllen.</p>
<p>Die Datenschutzbestimmungen von Microsoft Teams sind unter folgendem Link zu finden:
https://learn.microsoft.com/de-de/microsoftteams/teams-privacy</p>

<h4>Datenkategorien:</h4>
<p>Die nachfolgenden Datenkategorien werden für die Verarbeitung Ihrer Teilnahme an einer Online Live
Yogastunde herangezogen:</p>
<p><li><b>Video & Audio Daten:</b> Alle TeilnehmerInnen können die Video-Übertragung anderer
TeilnehmerInnen sehen. Die Kamerafunktion kann jedoch deaktiviert oder die Kamera abgedeckt werden. Der
Name, der beim Betreten des Online-Meeting-Raums angegeben wird, wird anderen TeilnehmerInnen angezeigt.
</li></p>
<p><li><b>Metadaten:</b> Die IP Adresse und die Information Ihrer Hardware.</li></p>
<p><li><b>User Profile Daten:</b> Wenn Sie sich bei Microsoft Teams angemeldet haben, haben Sie direkt bei
Teams Ihren Vornamen, Nachnamen und optional auch ein Foto angegeben. Diese Daten werden ebenfalls
verarbeitet. Sie können auch ohne Anmeldunga an den Online Yogastunden teilnehmen, in diesem Fall wird nur der
von Ihnen angegebene Name verarbeitet.</li></p>
<p><li><b>Chat/Messaging während der Online Yogastunde:</b> Alle Daten, die im Chat bekannt gegeben
werden, werden dort entsprechend verarbeitet.</li></p>
<p>The Mermaid Yoga führt keine Aufzeichnungen der Online Live Stunden durch. Die Möglichkeit der
Aufzeichnung wird den anderen TeilnehmerInnen ebenso nicht zur Verfügung gestellt. Es wird jedoch darauf

hingewiesen, dass eine mögliche Aufzeichnung durch andere TeilnehmerInnen mit einem anderen Gerät möglich
ist. Jedoch widerspricht die Aufnahme einer Online Yogastunden den AGB von The Mermaid Yoga und ist somit
auch ein Verstoß gegen das Privatrecht.</p>
{/*
<h2>Google reCAPTCHA</h2>
<p>Wir nutzen „Google reCAPTCHA“ (im Folgenden „reCAPTCHA“) auf dieser Website. Anbieter ist
die Google Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.</p>
<p>Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf dieser Website (z. B. in einem
Kontaktformular) durch einen Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu
analysiert reCAPTCHA das Verhalten des Websitebesuchers anhand verschiedener Merkmale. Diese
Analyse beginnt automatisch, sobald der Websitebesucher die Website betritt. Zur Analyse wertet
reCAPTCHA verschiedene Informationen aus (z. B. IP-Adresse, Verweildauer des Websitebesuchers auf
der Website oder vom Nutzer getätigte Mausbewegungen). Die bei der Analyse erfassten Daten werden an
Google weitergeleitet.</p>
<p>Die reCAPTCHA-Analysen laufen vollständig im Hintergrund. Websitebesucher werden nicht darauf
hingewiesen, dass eine Analyse stattfindet.</p>
<p>Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
Websitebetreiber hat ein berechtigtes Interesse daran, seine Webangebote vor missbräuchlicher
automatisierter Ausspähung und vor SPAM zu schützen. Sofern eine entsprechende Einwilligung
abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO
und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
Einwilligung ist jederzeit widerrufbar.</p>

<p>Weitere Informationen zu Google reCAPTCHA entnehmen Sie den Google-
Datenschutzbestimmungen und den Google Nutzungsbedingungen unter folgenden Links:

https://policies.google.com/privacy?hl=de und https://policies.google.com/terms?hl=de.</p>*/}
</div>
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Datenschutz;
