import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Particle from "../Particle";
import {Link} from "react-router-dom";
import blog1 from "../../Assets/Blog7_Foto1.webp";
import blog2 from "../../Assets/Blog7_Foto2.webp";
import blog3 from "../../Assets/Blog7_Foto3.webp";
import blog4 from "../../Assets/Blog7_Foto4.webp";
import blog5 from "../../Assets/Blog7_Foto5.webp";
import blog6 from "../../Assets/Blog7_Foto6.webp";

function BlogYoga7() {
  return (
    <Container fluid="true" className="blog-section" id="Blog">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "0px",
            }}
          >
          </Col>
          <Col md={12} style={{
            paddingTop: "20px",
              justifyContent: "center" }}>
        </Col>
        </Row>
        
        <Row>
        <Card className="blog-card" style={{ backgroundColor: 'transparent' }}>
            <Card.Body>
                <Card.Text>
                
 
<h1>Plus Size Yoga – Warum Körperideale im Yoga keinen Platz haben</h1>

<p>Dass unsere Gesellschaft von Schönheitsstandards und unrealistischen Körperidealen geprägt ist, ist schon lange kein Geheimnis mehr. Diese Standards werden oft durch die Medien, Werbung und soziale Netzwerke verstärkt und beeinflussen das Selbstwertgefühl vieler Menschen negativ. Für die von uns, die diesen gängigen Schönheitsnormen nicht entsprechen, kann es eine tägliche Herausforderung sein, sich wohl und akzeptiert zu fühlen. Dieses Gefühl der Nicht-Zugehörigkeit wird besonders deutlich, wenn es um Aktivitäten geht, die oft mit schlanken und flexiblen Körpern assoziiert werden, wie beispielsweise Yoga. In einer Kultur, die oft nur eine begrenzte Bandbreite an Körperformen und -größen als erstrebenswert darstellt, fühlen sich Menschen, die von diesen Standards abweichen, unsicher oder sogar unwillkommen. Das Fehlen von Diversität und die Verherrlichung eines bestimmten Körperideals kann dazu führen, dass sich viele Personen, insbesondere Plus Size Individuen, ausgeschlossen oder unterrepräsentiert fühlen. </p>

<p>Dieses Phänomen macht es dringend notwendig, eine inklusivere und diversere Darstellung von Körpern in der Yoga-Community zu fördern und sicherzustellen, dass jeder, unabhängig von seiner Körperform oder -größe, sich in dieser Praxis willkommen und akzeptiert fühlen kann. </p>

<img src={blog1} alt="Wild Thing" style={{ maxWidth: '90%' , maxHeight: '500px', paddingBottom: '20px', paddingTop: '20px' }} />


<h2>Warum Plus Size Yoga noch nicht so verbreitet ist</h2>

<p>Doch obwohl Yoga in den letzten Jahren immer beliebter geworden ist und eine breite Vielfalt von Menschen angezogen hat, ist Plus Size Yoga im Vergleich zu anderen Yoga-Stilen leider immer noch nicht so weit verbreitet und in der Yoga-Welt angekommen. Dafür gibt es mehrere Gründe: </p>

<h3>Mangelnde Repräsentation und Aufklärung</h3>

<p>In vielen Mainstream-Yoga-Studios und Fitnessstudios gibt es leider immer noch sehr wenige Plus Size LehrerInnen bzw. TrainerInnen. Dies führt dazu, dass sich Plus Size Personen nicht repräsentiert und sich daher nicht wohl fühlen, Yoga zu praktizieren oder beispielsweise ins Gym zu gehen. Darüber hinaus mangelt es oft an Aufklärung über die Bedürfnisse und Anpassungen, die für Plus Size Yogapraktizierende erforderlich sein können. Ohne entsprechende Informationen fühlen sich Plus Size Personen möglicherweise unsicher und nicht ermutigt, an Yoga-Kursen teilzunehmen. </p>

<h3>Mangel an spezialisierten Kursen und LehrerInnen</h3>

<p>Es gibt noch nicht genügend spezialisierte Kurse und LehrerInnen, die sich auf Plus Size Yoga spezialisiert haben. Viele YogalehrerInnen sind möglicherweise nicht ausreichend geschult, um auf die Bedürfnisse von Plus Size Praktizierenden einzugehen oder entsprechende Modifikationen anzubieten. </p>

<h3>Negative Stereotypen und Vorurteile</h3>

<p>Es gibt immer noch viele negative Stereotypen und Vorurteile in Bezug auf Plus Size Körper. Diese Stereotypen können dazu führen, dass Plus Size Personen glauben, dass sie nicht fit genug sind, um Yoga zu praktizieren, oder dass Yoga nicht für sie geeignet ist. Es kommt dazu, dass Plus Size Personen sich zurückziehen und sich nicht trauen, Yoga auszuprobieren, selbst wenn sie eigentlich großes Interesse daran hätten. Es ist wichtig zu erkennen, dass diese negativen Stereotypen und Vorurteile nicht der Realität entsprechen und dass Yoga für Menschen aller Körperformen und -größen zugänglich ist. Es bedarf jedoch einer Umgebung, die frei von Urteilen und Vorurteilen ist, um Plus Size Personen zu ermutigen, die Vorteile von Yoga zu genießen und ihre eigene Praxis zu entwickeln. </p>


<h3>Kommerzielle Interessen und Schönheitsideale</h3>

<p>In der Fitness- und Wellnessbranche werden oft bestimmte Schönheitsideale gefördert, die schlanken Körpern entsprechen. Dies kann dazu führen, dass Plus Size Yoga weniger finanziell attraktiv erscheint, da es möglicherweise nicht so gut in das kommerzielle Bild von Fitness und Wellness passt. Ohne entsprechende Werbung und Unterstützung können Plus Size Yogakurse möglicherweise nicht genügend Teilnehmer anziehen, um wirtschaftlich rentabel zu sein. </p>

<p>Insgesamt gibt es also eine Reihe von Faktoren, die dazu beitragen, dass Plus Size Yoga noch nicht so verbreitet ist wie andere Yoga-Praktiken. Um diese Barrieren zu überwinden und Plus Size Yoga zugänglicher zu machen, ist es wichtig, dass die Yoga-Community und die Fitnessindustrie sich stärker für Diversität und Inklusion einsetzen und spezielle Programme und Ressourcen für Plus Size Praktizierende anbieten. </p>


<img src={blog2} alt="Lizard" style={{ maxWidth: '90%' , maxHeight: '500px', paddingBottom: '20px', paddingTop: '20px' }} />



<h2>Warum du dich trauen solltest, eine Plus Size Yoga Stunde zu besuchen</h2>

<p>Insgesamt ist es wichtig zu erkennen (und ich kann das wirklich nicht oft genug sagen), dass Yoga für jeden Körper und jedes Fitnesslevel zugänglich ist. Durch eine positive und unterstützende Einstellung sowie die Bereitschaft, sich anzupassen und auf die Bedürfnisse des eigenen Körpers zu hören, können Plus Size Personen die vielen Vorteile von Yoga genießen und auf dem Weg zur Selbstliebe und Selbstakzeptanz voranschreiten. </p>

<p>Aber welche weiteren Gründe gibt es, warum du eine Plus Size Yogastunde besuchen solltest? Diese Frage hat viele Antworten, die die Vorteile und die transformative Kraft von Yoga für Menschen mit verschiedenen Körpertypen und -größen betonen. Hier sind einige Gründe, warum du eine Plus Size Yogastunde in Betracht ziehen solltest:</p>

<p><li><b>Inklusive und unterstützende Umgebung: </b>
Plus Size Yogastunden werden oft in einer Umgebung angeboten, die darauf abzielt, Menschen aller Größen willkommen zu heißen und zu unterstützen. Diese Klassen sind oft sensibel gestaltet, um die Bedürfnisse von Plus Size Praktizierenden zu berücksichtigen und eine Atmosphäre der Akzeptanz und Unterstützung zu schaffen. Du kannst dich also sicher und wohl fühlen, wenn du an einer Plus Size Yogastunde teilnimmst – Scham und Angst haben hier keinen Platz.</li></p>


<p><li><b>Anpassungen und Modifikationen: </b>
Plus Size Yogastunden bieten oft spezifische Anpassungen und Modifikationen an, die darauf abzielen, die Praxis für größere Körperbauarten zugänglicher zu machen. YogalehrerInnen, die sich auf Plus Size Yoga spezialisiert haben, sind geschult, um alternative Positionen und Hilfsmittel anzubieten, die es dir ermöglichen, die Übungen sicher und effektiv durchzuführen, unabhängig von deiner Größe oder Flexibilität. </li></p>

<p><li><b>Verbesserung der Körperwahrnehmung und des Selbstbewusstseins: </b>
Yoga kann dabei helfen, das Körperbewusstsein und das Selbstbewusstsein zu verbessern, unabhängig von deiner Größe oder Form. Durch die Praxis von Plus Size Yoga kannst du lernen, deinen Körper zu akzeptieren und zu schätzen, sowie ein tieferes Verständnis für deine eigenen Grenzen und Möglichkeiten entwickeln. Dies kann dazu beitragen, dein Selbstvertrauen zu stärken und ein positives Körperbild aufzubauen. </li></p>

<p><li><b>Stressabbau und Entspannung: </b>
Yoga ist bekannt für seine stressabbauenden und entspannenden Eigenschaften. Die Praxis von Plus Size Yoga kann dir helfen, Stress abzubauen und dich zu entspannen, indem du dich auf deinen Atem und deine Bewegungen konzentrierst. Diese Momente der Ruhe und Achtsamkeit können dazu beitragen, deine geistige und körperliche Gesundheit zu verbessern und ein Gefühl des Wohlbefindens zu fördern. </li></p>

<p><li><b>Stärkung von Körper und Geist: </b>
Plus Size Yoga kann eine wirksame Möglichkeit sein, deinen Körper zu stärken und deine Flexibilität zu verbessern. Durch regelmäßige Praxis kannst du deine Muskeln aufbauen, deine Körperhaltung verbessern und deine Beweglichkeit erhöhen. Gleichzeitig kannst du auch die Verbindung zwischen Körper und Geist stärken, indem du dich auf deine Atmung und deine Gedanken konzentrierst und so ein Gefühl der Ausgeglichenheit und Harmonie schaffst. </li></p>

<p>Insgesamt gibt es viele gute Gründe, eine Plus Size Yogastunde zu besuchen. Egal ob du Anfänger bist oder schon Erfahrung hast, Plus Size Yoga kann eine wunderbare Möglichkeit sein, deine körperliche und geistige Gesundheit zu verbessern und ein tieferes Verständnis für deinen Körper und deine Bedürfnisse zu entwickeln. </p>


<img src={blog3} alt="Halbmond" style={{ maxWidth: '90%' , maxHeight: '500px', paddingBottom: '20px', paddingTop: '20px' }} />



<h2>Auf was man bei Plus Size Yoga achten sollte</h2>

<p>Bei Plus Size Yoga ist es wichtig, auf den eigenen Körper zu hören und ihn zu respektieren. Bevor man mit Yoga beginnt, ist es ratsam, einen Arzt zu konsultieren, um sicherzustellen, dass keine gesundheitlichen Bedenken bestehen. Darüber hinaus sollte man sich nach YogalehrerInnen umsehen, die Erfahrung im Unterrichten von Plus Size Yoga haben und sensibel auf die Bedürfnisse ihrer SchülerInnen eingehen können. </p>

<p>Beim Kauf von Yogakleidung sollte man auf bequeme und atmungsaktive Materialien achten, die genügend Bewegungsfreiheit bieten. Es ist wichtig, sich in seiner Kleidung wohl und selbstbewusst zu fühlen, um sich ganz auf die Praxis konzentrieren zu können. </p>

<p>Einige Yogahaltungen können für Plus Size Personen herausfordernd sein, insbesondere solche, die viel Flexibilität und Stärke erfordern. Dazu gehören zum Beispiel fortgeschrittene Umkehrhaltungen wie der Kopfstand oder der Handstand. In solchen Fällen ist es wichtig, alternative Positionen oder Modifikationen zu praktizieren, die den individuellen Bedürfnissen und Fähigkeiten entsprechen. </p>

<p>Zum Beispiel kann statt des Kopfstands die unterstützte Schulterbrücke praktiziert werden, bei der der Kopf und die Schultern auf dem Boden bleiben und die Hüften angehoben werden. Für den Handstand kann man Übungen an der Wand durchführen, um Kraft und Gleichgewicht zu entwickeln, bevor man sich an die volle Haltung wagt. </p>

<p>Drehungen und Vorwärtsbeugen stellen für Plus Size Personen oft eine besondere Herausforderung dar, da der Bauch häufig im Weg ist und es schwierig sein kann, eine optimale Ausrichtung zu finden. Bei stehenden Vorwärtsbeugen können Yoga-Blöcke eine entscheidende Rolle spielen, um den Boden näher zu bringen und die Dehnung der Rückseite der Oberschenkel und des unteren Rückens zu erleichtern. Die Blöcke ermöglichen es den Praktizierenden, ihre Hände abzustützen und gleichzeitig eine gute Stabilität zu bewahren. In Sitzvorwärtsbeugen ist es wichtig zu betonen, dass das Ziel nicht darin besteht, die Stirn an die Beine oder den Boden zu bringen. Selbst ein leichtes Vorbeugen des Oberkörpers kann bereits dazu beitragen, die Zielbereiche wie die Rückseite der Oberschenkel und den unteren Rücken zu dehnen. </p>

<img src={blog4} alt="Vorwärtsbeuge" style={{ maxWidth: '90%' , maxHeight: '500px', paddingBottom: '20px', paddingTop: '20px' }} />


<p>Darüber hinaus gestalten sich Drehungen im Sitzen oder im Ausfallschritt oft als schwierig für Plus Size Personen. Dennoch gibt es auch hier zahlreiche Variationen und Anpassungen, die es ermöglichen, einen schönen Twist durchzuführen. Zum Beispiel können die Hände als Stütze auf dem Boden oder einem Block platziert werden, um die Stabilität zu verbessern und die Drehbewegung zu unterstützen. Auch hier gilt, dass bereits leichte Drehungen zu einer Mobilisierung der Wirbelsäule beitragen und das Ziel somit erreicht ist. </p>

<img src={blog5} alt="Twist" style={{ maxWidth: '90%' , maxHeight: '500px', paddingBottom: '20px', paddingTop: '20px' }} />


<h2>Was ich als Yogalehrerin in meinen Plus Size Yogastunden gelernt habe</h2>

<p>Als Yogalehrerin, die Plus Size Yogastunden anbietet, habe ich eine Fülle von wertvollen Lektionen gelernt, die meine Herangehensweise an das Unterrichten und meine Wahrnehmung von Körperpositivität und Selbstakzeptanz vertieft haben. Hier sind einige der wichtigsten Erkenntnisse, die ich aus meinen Plus-Size-Yogastunden gewonnen habe:</p>

<p><li><b>Individualität respektieren: </b>
Jeder Körper ist einzigartig und hat seine eigenen Bedürfnisse und Grenzen. In meinen Plus Size Yogastunden habe ich gelernt, die Individualität meiner SchülerInnen zu respektieren und auf ihre unterschiedlichen Fähigkeiten und Einschränkungen einzugehen. Ich ermutige meine SchülerInnen, auf ihre eigenen Körper zu hören und die Praxis an ihre individuellen Bedürfnisse anzupassen. </li></p>

<p><li><b>Empathie und Sensibilität entwickeln: </b>
Die Praxis des Yoga erfordert Empathie und Sensibilität gegenüber den Bedürfnissen meiner SchülerInnen. Ich habe gelernt, einfühlsam zu sein und meine Schüler zu ermutigen, sich wohl und akzeptiert zu fühlen, unabhängig von ihrer Körperform oder -größe. Ich schaffe einen Raum, der frei von Urteilen und Erwartungen ist, und ermutige meine SchülerInnen, sich selbst mit Mitgefühl und Freundlichkeit zu behandeln. </li></p>

<p><li><b>Anpassung und Modifikation: </b>
In meinen Plus Size Yogastunden betone ich die Bedeutung von Anpassung und Modifikation. Ich biete alternative Positionen und Hilfsmittel an, um die Praxis für größere Körperbauarten zugänglicher zu machen und mögliche Verletzungen zu vermeiden. Ich ermutige meine SchülerInnen, die Positionen so anzupassen, dass sie sich für sie sicher und komfortabel anfühlen, und betone die Bedeutung von Selbstfürsorge und Selbstmitgefühl. </li></p>

<p><li><b>Körperpositivität fördern: </b>
Eine der wichtigsten Lektionen, die ich aus meinen Plus Size Yogastunden gelernt habe, ist die Bedeutung von Körperpositivität und Selbstakzeptanz. Ich ermutige meine Schüler, stolz auf ihre Körper zu sein und sie zu feiern, unabhängig von den gängigen Schönheitsstandards. Ich betone die Vielfalt und Schönheit aller Körper und fördere ein Gefühl der Selbstliebe und Selbstachtung. </li></p>


<img src={blog6} alt="Schneidersitz" style={{ maxWidth: '90%' , maxHeight: '500px', paddingBottom: '20px', paddingTop: '20px' }} />


<h2>Fazit</h2>

<p>In einer Welt, die oft von eng definierten Schönheitsstandards und unrealistischen Körperidealen geprägt ist, kann es für viele Menschen eine Herausforderung sein, sich in ihrer Haut wohl und akzeptiert zu fühlen. Besonders deutlich wird dieses Gefühl der Ausgrenzung in Bereichen wie Yoga, die oft mit einem bestimmten Körperbild in Verbindung gebracht werden. Doch diese Vorstellungen sollten nicht das Fundament einer Praxis sein, die eigentlich darauf abzielt, Körper, Geist und Seele in Einklang zu bringen.</p>

<p>Der Mangel an spezialisierten Kursen und Lehrkräften sowie die negativen Stereotypen und Vorurteile gegenüber Plus Size Körpern sind bedeutende Hindernisse für eine inklusive Yogapraxis. Die Yoga-Gemeinschaft und die Fitnessindustrie müssen sich stärker für Diversität und Inklusion einsetzen, um sicherzustellen, dass Menschen aller Körperformen und -größen sich willkommen und akzeptiert fühlen.</p>

<p>Plus Size Yoga bietet nicht nur physische, sondern auch emotionale und spirituelle Vorteile. Es schafft einen Raum der Selbstfürsorge und Selbstakzeptanz, der es Menschen ermöglicht, sich mit Mitgefühl und Freundlichkeit zu behandeln. Durch die Praxis können Plus Size Praktizierende ihre Kraft, Flexibilität und Körperwahrnehmung verbessern und ein tieferes Verständnis für sich selbst entwickeln.</p>

<p>Deshalb sollte sich jeder, unabhängig von seiner Körperform oder -größe, ermutigt fühlen, eine Plus Size Yogastunde zu besuchen. Es ist an der Zeit, dass Yoga zu einer Praxis wird, die allen Menschen zugänglich ist, und dass wir uns von den engen Grenzen gängiger Schönheitsstandards lösen, um Raum für Vielfalt und Selbstliebe zu schaffen.</p>

<p>Sollte dich jetzt das Interesse an Plus Size Yoga gepackt haben, dann besuche gerne einmal eine meiner Online Plus Size Yoga Stunden. <Link className="purpledark" as={Link} to="/course">
               Hier
        </Link> kannst du dich dazu anmelden. Ich würde mich sehr darüber freuen, dich kennenzulernen und mit dir gemeinsam Yoga zu praktizieren! Lass mich dir zeigen, dass du nicht außerordentlich biegsam, flexibel und dünn sein musst, um Yoga zu praktizieren. </p>

<p>Alles Liebe, Bianca</p> 

                </Card.Text>
            </Card.Body>
            <Card.Footer className="blog-card">3. März 2024</Card.Footer>
            </Card>
        </Row>
      </Container>
    </Container>
  );
}

export default BlogYoga7;
